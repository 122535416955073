export default {
  'maint.constants.option.MaintRequestType.BATCH_IMPORT':
    'การบำรุงรักษาเป็นชุด',
  'maint.constants.option.MaintRequestType.EMERGENCY_RESCUE':
    'การช่วยเหลือฉุกเฉิน',
  'maint.constants.option.MaintRequestType.MAINT_RESERVATION':
    'นัดหมายเพื่อการบำรุงรักษา',
  'maint.constants.option.MaintRequestType.STATION_BATCH_MAINT':
    'การบำรุงรักษาเป็นชุด',
  'maint.constants.option.MaintUncoveredQuotationStatus.CONFIRMED':
    'ยืนยันแล้ว',
  'maint.constants.option.MaintUncoveredQuotationStatus.CONFIRMED_NOT_UPLOADED_VOUCHER':
    'ยืนยันว่าใบรับรองหายไป',
  'maint.constants.option.MaintUncoveredQuotationStatus.PENDING_CONFIRM':
    'ที่จะได้รับการยืนยัน',
  'maint.constants.option.MaintUncoveredQuotationStatus.PENDING_REVIEW':
    'รอการตรวจสอบ',
  'maint.constants.option.MaintUncoveredQuotationStatus.REJECTED': 'ถูกไล่ออก',
  'maint.constants.option.MaintUncoveredQuotationStatus.REVOKED': 'เป็นโมฆะ',
};
