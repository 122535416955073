export default {
  'user.manage.details.templateUserGroups': '{column}{userGroups}',
  'user.manage.details.belonging': 'เป็นของ',
  'user.manage.details.locked': 'ล็อค',
  'user.manage.details.unlock': 'ปลดล็อค',
  'user.manage.details.normal': 'ปกติ',
  'user.manage.details.dutyDate': 'วันที่ปฏิบัติหน้าที่',
  'user.manage.details.dutyStatus': 'สถานะหน้าที่',
  'user.manage.details.failedReason': 'สาเหตุของความล้มเหลว',
  'user.manage.details.schedulingTable': 'กำหนดการ',
  'user.manage.details.schedule': 'การจัดตารางเวลา',
  'user.manage.details.exportRemark':
    'หมายเหตุ: &#10;1. จำเป็นต้องกรอกข้อมูลในช่องที่มีเครื่องหมายสีแดง &#10;2. หมายเลขโทรศัพท์มือถือต้องเป็นหมายเลข 11 หลักที่มีอยู่ในระบบ &#10;3. วันที่ปฏิบัติหน้าที่ต้องระบุในรูปแบบ "2020/1/1" หรือ "2020-1-1"',
  'user.manage.details.importFailedName': 'ไม่สามารถนำเข้าข้อมูลลงในกำหนดการ',
  'user.manage.details.lockTips': 'บัญชีถูกล็อคและไม่สามารถเข้าสู่ระบบได้',
  'user.manage.details.basicSetting': 'การตั้งค่าพื้นฐาน',
  'user.manage.details.switchboardNumber': 'หมายเลขส่วนขยาย',
  'user.manage.details.maintApprovalSetting':
    'การตั้งค่าการอนุมัตินโยบายการบำรุงรักษา',
  'user.manage.details.assignedVehicleTeam': 'กองเรือที่กำหนด',
  'user.manage.details.searchVehicleTeam': 'ค้นหากองเรือ',
  'user.manage.details.accountRole': 'บทบาทบัญชี',
  'user.manage.details.assignedRole': 'บทบาทที่ได้รับมอบหมาย',
  'user.manage.details.accountPermissions': 'สิทธิ์บัญชี',
};
