import { isNotEmptyArray } from '@/utils/utils-array.js';

/**
 * 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
}

/**
 * 格式化时间
 * @param time
 * @param option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    const realMonth = d.getMonth() + 1;
    return `${realMonth}月${d.getDate()}日${d.getHours()}时${d.getMinutes()}分`;
  }
}

/**
 * 将url请求参数转为json格式
 * @param url
 * @returns {{}|any}
 */
export function paramObj(url) {
  const search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}',
  );
}

/**
 * 父子关系的数组转换成树形结构数据
 * @param data
 * @returns {*}
 */
export function translateDataToTree(data) {
  const parent = data.filter(
    (value) => value.parentId === 'undefined' || value.parentId == null,
  );
  const children = data.filter(
    (value) => value.parentId !== 'undefined' && value.parentId != null,
  );
  const translator = (parent, children) => {
    parent.forEach((parent) => {
      children.forEach((current, index) => {
        if (current.parentId === parent.id) {
          const temp = JSON.parse(JSON.stringify(children));
          temp.splice(index, 1);
          translator([current], temp);
          typeof parent.children !== 'undefined'
            ? parent.children.push(current)
            : (parent.children = [current]);
        }
      });
    });
  };
  translator(parent, children);
  return parent;
}

/**
 * 树形结构数据转换成父子关系的数组
 * @param data
 * @returns {[]}
 */
export function translateTreeToData(data) {
  const result = [];
  data.forEach((item) => {
    const loop = (data) => {
      result.push({
        id: data.id,
        name: data.name,
        parentId: data.parentId,
      });
      const child = data.children;
      if (child) {
        for (let i = 0; i < child.length; i++) {
          loop(child[i]);
        }
      }
    };
    loop(item);
  });
  return result;
}

/**
 * 10位时间戳转换
 * @param time
 * @returns {string}
 */
export function tenBitTimestamp(time) {
  const date = new Date(time * 1000);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? '' + m : m;
  let d = date.getDate();
  d = d < 10 ? '' + d : d;
  let h = date.getHours();
  h = h < 10 ? '0' + h : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? '0' + minute : minute;
  second = second < 10 ? '0' + second : second;
  return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second;
}

/**
 * 13位时间戳转换
 * @param time
 * @returns {string}
 */
export function thirteenBitTimestamp(time) {
  const date = new Date(time / 1);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? '' + m : m;
  let d = date.getDate();
  d = d < 10 ? '' + d : d;
  let h = date.getHours();
  h = h < 10 ? '0' + h : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? '0' + minute : minute;
  second = second < 10 ? '0' + second : second;
  return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second;
}

/**
 * 获取随机id
 * @param length
 * @returns {string}
 */
export function uuid(length = 32) {
  const num = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  let str = '';
  for (let i = 0; i < length; i++) {
    str += num.charAt(Math.floor(Math.random() * num.length));
  }
  return str;
}

/**
 * m到n的随机数
 * @param m
 * @param n
 * @returns {number}
 */
export function random(m, n) {
  return Math.floor(Math.random() * (m - n) + n);
}

/**
 * addEventListener
 * @type {function(...[*]=)}
 */
export const on = (function () {
  return function (element, event, handler, useCapture = false) {
    if (element && event && handler) {
      element.addEventListener(event, handler, useCapture);
    }
  };
})();

/**
 * removeEventListener
 * @type {function(...[*]=)}
 */
export const off = (function () {
  return function (element, event, handler, useCapture = false) {
    if (element && event) {
      element.removeEventListener(event, handler, useCapture);
    }
  };
})();

// 根据citycode查找对应的省市区数据code
export const getCityCodeAll = (function () {
  return function (arr, arrs, val) {
    if (val === 'code') {
      arr.forEach((item) => {
        arrs.push(item.value);
        if (item.children && item.children.length) {
          getCityCodeAll(item.children, arrs, 'code');
        }
      });
    }
    if (val === 'name') {
      arr.forEach((item) => {
        arrs.push(item.label);
        if (item.children && item.children.length) {
          getCityCodeAll(item.children, arrs, 'name');
        }
      });
    }
  };
})();

/**
 * 校验图片大小
 * @param fileList:需要校验的图片数组
 * @param size:图片大小限制
 */
export function checkImageSize(fileList, size) {
  return new Promise((resolve, reject) => {
    if (isNotEmptyArray(fileList)) {
      for (let item = 0; item < fileList.length; item++) {
        if (fileList[item].size > size * 1024 * 1024) {
          fileList[item].status = 'error';
          reject(fileList[item]);
          return;
        }
      }
      resolve();
    } else {
      resolve();
    }
  });
}

/**
 * 添加单位
 * @param {String | Number} value 单位值 字符串单位/数值
 */
export function addUnit(value) {
  const REGEXP = /^-?\d+(\.\d+)?$/;
  return REGEXP.test('' + value) ? value + 'px' : value;
}

/**
 * 移除数组中没有数据的项
 * @param {Array} list 数组项
 * return 有数据的数组
 */
export function removeEmptyItem(list) {
  const copyList = JSON.parse(JSON.stringify(list));
  return copyList.filter((item) =>
    Object.keys(item).some((key) =>
      item[key] ? item[key]['code'] : item[key],
    ),
  );
}

/**
 * 四舍五入
 * @param {Number|String} number 需四舍五入的数字
 * @param {Number|String} precision 小数位
 * @returns {string} 四舍五入后的数字
 */

export function round(number, precision) {
  if (number === '') {
    return '';
  }
  number = Number(number);
  if (Number.isNaN(number)) {
    return '';
  }

  precision =
    precision == null
      ? 0
      : precision >= 0
        ? Math.min(precision, 292)
        : Math.max(precision, -292);
  if (precision) {
    // 使用指数符号移位以避免浮点问题
    let pair = `${number}e`.split('e');
    const value = Math.round(`${pair[0]}e${+pair[1] + precision}`);
    pair = `${value}e`.split('e');
    let result = String(+`${pair[0]}e${+pair[1] - precision}`);
    // 小数位
    const decimal = result.split('.')[1];
    // 补0的次数
    let len = 0;
    if (decimal == null) {
      result += '.';
      len = precision;
    } else {
      len = precision - decimal.length;
    }
    // 补0
    for (var i = 0; i < len; i++) {
      result += '0';
    }
    return result;
  }
  return Math.round(number);
}

/**
 * 数字格式化，可用于数字输入框
 * @param {Number | String} current 当前输入值
 * @param {Number | String} prev 上次的输入值
 * @param {Object} config withNegative：是否包含符号，withDecimal：是否包含小数
 * @returns {Number | String} 格式化后的内容
 */
export function numberFormatMixin(
  current,
  prev,
  config = { withNegative: false, withDecimal: false },
) {
  let reg = /^[0-9]*$/; // 默认为纯数字

  if (config.withNegative && config.withDecimal) {
    // 允许负号和小数点
    reg = /^-?\d*(\.\d*)?$/;
  } else if (config.withNegative) {
    // 只允许负号
    reg = /^-?\d*$/;
  } else if (config.withDecimal) {
    // 只允许小数点
    reg = /^\d*(\.\d*)?$/;
  }

  return reg.test(current) ? current : prev;
}

/**
 * 重置表单数据
 * @param {Object} formData 表单数据
 * @returns {Object} 重置后的表单数据
 */
export function resetFormData(formData) {
  if (checkType(formData) !== 'object') {
    console.warn('resetFormData方法参数类型校验失败，期望类型为object');
    return;
  }

  const result = {};

  Object.keys(formData).forEach((key) => {
    const eleType = checkType(formData[key]);
    switch (eleType) {
      case 'array':
        result[key] = [];
        break;
      case 'object':
        result[key] = {};
        break;

      default:
        result[key] = null;
        break;
    }
  });

  return result;

  function checkType(arg) {
    return Object.prototype.toString.call(arg).slice(8, -1).toLowerCase();
  }
}

/**
 * 过滤传输数据(排除优先)
 * @param {Object} translateData 传输数据
 * @param {Array} includes 传输数据包含的字段
 * @param {Array} excludes 传输数据排除的字段
 * @returns {Object} 过滤后的传输数据
 */
export function filterTranslateData(
  translateData,
  { includes, excludes } = {},
) {
  if (checkType(translateData) !== 'object') {
    console.warn(
      'filterTranslateData方法参数translateData类型校验失败，期望类型为object',
    );
    return;
  }

  const result = {};
  const keys = Object.keys(translateData);
  for (let i = 0; i < keys.length; i++) {
    // key在excludes选项中
    const isExcluded = excludes?.length && excludes.includes(keys[i]);
    // key不在includes中
    const isNotIncluded = includes?.length && !includes.includes(keys[i]);
    if (isExcluded || isNotIncluded) {
      continue;
    }
    result[keys[i]] = translateData[keys[i]];
  }

  return result;

  function checkType(arg) {
    return Object.prototype.toString.call(arg).slice(8, -1).toLowerCase();
  }
}

/**
 * 拍平树形结构
 * @param {Array} tree 树形数据
 * @returns {Array}
 */
export function flatTree(tree) {
  tree = JSON.parse(JSON.stringify(tree));
  let res = [];
  tree.forEach((item) => {
    res.push(item);
    item.children && res.push(...flatTree(item.children));
  });
  return res.map((item) => {
    if (item.children) {
      delete item.children;
    }
    return item;
  });
}

/**
 * 根据键记录和值记录构造可选项数组
 * @param {*} optValue 键记录
 * @param {*} optLabel 值记录
 * @returns 构造后的可选项数组
 */
export function constructOptArray(optValue, optLabel) {
  return Object.keys(optValue).map((key) => ({
    value: key,
    label: optLabel[key] ?? key,
  }));
}

/**
 * 移除所有的图片预览元素
 */
export function removeAllImagePreview() {
  const imagePreviewRoots = document.querySelectorAll(
    '.ant-image-preview-root',
  );
  for (let i = 0; i < imagePreviewRoots.length; i++) {
    const element = imagePreviewRoots[i].parentElement;
    if (document.body.contains(element)) {
      document.body.removeChild(element);
    }
  }
}

/**
 * 构建[start,end)的数组
 * @param {number} start 开始索引
 * @param {number} end 结束索引
 */
export function range(start, end) {
  return Array.from(Array(end - start), (_v, k) => k);
}

export function getCurrencySymbol(countryCode) {
  let currencySymbol;
  switch (countryCode) {
    case 'THA':
      currencySymbol = '฿';
      break;
    case 'PHL':
      currencySymbol = '₱';
      break;
    case 'MYS':
      currencySymbol = 'RM';
      break;
    default:
      currencySymbol = '￥';
      break;
  }
  return currencySymbol;
}

export function getCurrency(countryCode) {
  let currency;
  switch (countryCode) {
    case 'THA':
      currency = '泰铢';
      break;
    case 'PHL':
      currency = '比索';
      break;
    case 'MYS':
      currency = '林吉特';
      break;
    default:
      currency = '元';
      break;
  }
  return currency;
}

/**
 * 设置最后一个操作按钮的样式设置为主按钮样式primary
 * @param {string} selector 样式选择器
 */
export function setPrimaryButton(selector) {
  const buttons = document.querySelectorAll(selector);
  if (buttons.length === 0) return;
  buttons[buttons.length - 1].classList.add('ant-btn-primary');
}
