import resetPasswordCommon from '@/locales/th-TH/reset-password/reset-password-common.js';
import resetPasswordComponents from '@/locales/th-TH/reset-password/reset-password-components.js';
import resetPasswordCompositions from '@/locales/th-TH/reset-password/reset-password-compositions.js';
import resetPasswordRouter from '@/locales/th-TH/reset-password/reset-password-router.js';

export default {
  ...resetPasswordCommon,
  ...resetPasswordComponents,
  ...resetPasswordCompositions,
  ...resetPasswordRouter,
};
