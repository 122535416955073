export default {
  'directSupplyManage.common.brand': 'ยี่ห้อ',
  'directSupplyManage.common.category': 'หมวดหมู่',
  'directSupplyManage.common.changeType': 'เปลี่ยนประเภท',
  'directSupplyManage.common.close': 'ปิด',
  'directSupplyManage.common.count': 'ปริมาณ',
  'directSupplyManage.common.date': 'วันที่',
  'directSupplyManage.common.description': 'แสดงให้เห็น',
  'directSupplyManage.common.fittingsAliasName': 'ชื่ออุปกรณ์เสริม/นามแฝง',
  'directSupplyManage.common.fittingsName': 'ชื่ออุปกรณ์เสริม',
  'directSupplyManage.common.inventory': 'ในสต็อก',
  'directSupplyManage.common.inventoryQuantityChangeDetails':
    'รายละเอียดการเปลี่ยนแปลงปริมาณสินค้าคงคลัง',
  'directSupplyManage.common.model': 'แบบอย่าง',
  'directSupplyManage.common.no': 'เลขที่',
  'directSupplyManage.common.operate': 'ดำเนินงาน',
  'directSupplyManage.common.remark': 'หมายเหตุ',
  'directSupplyManage.common.status': 'สถานะ',
  'directSupplyManage.common.title': 'การจัดการชิ้นส่วนอุปทานโดยตรง',
  'directSupplyManage.common.totalPrice': 'ราคารวม({หน่วย})',
  'directSupplyManage.common.unit': 'หน่วย',
  'directSupplyManage.common.unitPrice': 'ราคาต่อหน่วย({หน่วย})',
  'directSupplyManage.common.viewDetails': 'ดูรายละเอียด',
  'directSupplyManage.common.yes': 'ใช่',
};
