export default {
  'vehicle.team.manage': 'การจัดการยานพาหนะ',
  'vehicle.team.details.searchVehicleTeamName': 'ชื่อทีม/รหัสทีม',
  'vehicle.team.details.vehicleRepairRequirement': 'ข้อกำหนดอุปกรณ์เสริม',
  'vehicle.team.details.fittingName': 'ชื่ออุปกรณ์เสริม/รหัสอุปกรณ์เสริม',
  'vehicle.team.details.fittingNameToSelect':
    'กรุณากรอกชื่ออุปกรณ์เสริมเพื่อเลือก',
  'vehicle.team.details.fittingBrand': 'อุปกรณ์เสริมยี่ห้อ',
  'vehicle.team.details.fittingBrandToSelect': 'กรุณาเลือกยี่ห้อ',
  'vehicle.team.details.fittingModelName':
    'รุ่นอุปกรณ์เสริม/ชื่อภาษาอังกฤษ/รหัสรุ่น',
  'vehicle.team.details.vehicleModel': 'รุ่นที่ใช้งานได้',
  'vehicle.team.details.mustDirectSupply': 'จำเป็นต้องจัดหาโดยตรงหรือไม่?',
  'vehicle.team.details.vehicleModelToSelect': 'กรุณาระบุรุ่นรถ',
  'vehicle.team.details.teamGroup': 'กลุ่ม',
  'vehicle.team.details.maintUncoveredOfferPrice': 'ราคาเกินประกัน',
  'vehicle.team.details.repairRequirement': 'ข้อกำหนดในการบำรุงรักษา',
  'vehicle.team.details.repairApplicationApproval': 'การอนุมัติการสมัครซ่อม',
  'vehicle.team.details.repairAutoDispatch': 'การส่งคำสั่งซื้ออัตโนมัติ',
  'vehicle.team.details.repairAutoDispatch.open': 'เปิดเครื่อง',
  'vehicle.team.details.repairAutoDispatch.close': 'ปิด',
  'vehicle.team.details.repairApplicationApprovalTip':
    'หากเปิดใช้งาน คำร้องขอการซ่อมแซมของผู้ขับขี่จะต้องได้รับอนุมัติจากหัวหน้าทีม',
  'vehicle.team.details.maintUncoveredQuotationConfirm':
    'การยืนยันการปล่อยตัวประกันตัว',
  'vehicle.team.details.maintUncoveredQuotationConfirmTip':
    'หากมีการกำหนดค่าให้เปิดหรือเปิดตามเงื่อนไข ค่าธรรมเนียมที่ไม่อยู่ภายใต้การรับประกันจะต้องได้รับการยืนยันด้วยตนเองและอัปโหลดพร้อมกับบัตรกำนัล',
  'vehicle.team.details.accountInformation': 'ข้อมูลบัญชี',
  'vehicle.team.details.accountNumber': 'จำนวนบัญชี',
  'vehicle.team.details.allAccountEnableStatus':
    'สถานะความพร้อมของบัญชีทั้งหมด',
  'vehicle.team.details.superAdministrator': 'ผู้ดูแลระบบระดับสูง',
  'vehicle.team.details.disableRemark':
    '(เมื่อปิดใช้งาน บัญชีทั้งหมดจะไม่สามารถเข้าสู่ระบบได้)',
  'vehicle.team.details.mobileNumberRemark':
    '(หมายเลขโทรศัพท์มือถือจะถูกใช้เป็นชื่อผู้ใช้)',
  'vehicle.team.details.passwordRemark':
    '(หากไม่ต้องการเปลี่ยนรหัสผ่าน ไม่จำเป็นต้องกรอกข้อนี้)',
  'vehicle.team.details.fittingVehicleModelExist':
    'อุปกรณ์เสริมนี้ได้กำหนดข้อกำหนดของแบรนด์สำหรับรุ่นปัจจุบัน โปรดอย่าเพิ่มซ้ำหลายครั้ง',
  'vehicle.team.details.country': 'ประเทศ',
  'vehicle.team.details.address': 'ที่อยู่',
  'vehicle.team.details.vehicleBusinessTypes': 'ประเภทความคุ้มครอง',
  'vehicle.team.details.transportType': 'ประเภทการขนส่ง',
  'vehicle.team.details.maintCoveredInfo': 'ข้อมูลการรับประกันภัย',
  'vehicle.team.details.maintCoveredVehicleNum': 'จำนวนรถที่เอาประกันภัย',
  'vehicle.team.details.expandInfo': 'ขยายข้อมูล',
  'vehicle.team.details.highSpeedProportion': 'อัตราส่วนความเร็วสูง',
  'vehicle.team.details.maintCoverageRemark': 'คำอธิบายความคุ้มครอง',
  'vehicle.team.details.workinghourName': 'ชื่อเวลาทำงาน/รหัสชั่วโมงทำงาน',
  'vehicle.team.details.applicableVehicleAttrValue': 'การกำหนดค่าโดยละเอียด',
  'vehicle.team.details.projectType': 'ประเภทโครงการ',
  'vehicle.team.details.projectName': 'ชื่อโครงการ',
  'vehicle.team.details.vehicleTeamOfferPrice': 'ราคาเรือเดินสมุทร',
  'vehicle.team.details.applicableVehicleGroups': 'กลุ่มยานพาหนะที่เกี่ยวข้อง',
  'vehicle.team.details.applicableVehicleAttr':
    'การกำหนดค่ายานพาหนะที่ใช้งานได้',
  'vehicle.team.details.applicableVehicleAttrValues':
    'การกำหนดค่าโดยละเอียดที่เกี่ยวข้อง',
  'vehicle.team.details.workinghourId': 'รหัสงาน',
  'vehicle.team.details.fittingId': 'รหัสอุปกรณ์เสริม',
  'vehicle.team.details.fittingModelId': 'รหัสรุ่น',
  'vehicle.team.details.contractedStationMaintUncoveredPip':
    'อัตราการขึ้นราคาสถานีลงนาม',
  'vehicle.team.details.cooperativeStationMaintUncoveredPip':
    'อัตราส่วนราคาที่เพิ่มขึ้นของสถานีสหกรณ์',
  'vehicle.team.details.temporaryStationMaintUncoveredPip':
    'อัตราส่วนการขึ้นราคาสถานีชั่วคราว',
  'vehicle.team.details.vehicleGroupNameAndStationType':
    'ประเภทความร่วมมือของกลุ่มยานพาหนะ/สถานีบริการ',
  'vehicle.team.details.maintUncoveredOfferPriceImport':
    'นำเข้าราคาต่อรองนอกการรับประกันสำหรับอุปกรณ์เสริมเวลาทำงาน',
  'vehicle.team.details.workinghourMaintUncoveredOfferPrice':
    'ราคาต่อรองได้สำหรับอุปกรณ์เสริมเวลาทำงานที่อยู่นอกการรับประกัน',
  'vehicle.team.details.maintUncoveredOfferPriceImportFailData':
    'การนำเข้าข้อมูลราคาข้อตกลงที่ไม่อยู่ภายใต้การรับประกันล้มเหลว',
  'vehicle.team.details.maintUncoveredPipSettings':
    'การตั้งค่าอัตราส่วนการเพิ่มราคาที่ไม่อยู่ภายใต้การรับประกัน',
  'vehicle.team.details.maintUncoveredPip':
    'อัตราส่วนการเพิ่มราคาที่ไม่อยู่ภายใต้การรับประกัน',
  'vehicle.team.details.maintUncoveredPipSettingsImport':
    'นำเข้าการตั้งค่าอัตราส่วนมาร์กอัปการรับประกันพิเศษ',
  'vehicle.team.details.maintUncoveredPipSettingsImportFailData':
    'นำเข้าข้อมูลอัตราส่วนมาร์กอัปการรับประกันเพิ่มเติมไม่สำเร็จ',
};
