export default {
  'stationMonthlyStatementConfirmation.common.amerceAmount': 'จำนวนเงินที่หัก',
  'stationMonthlyStatementConfirmation.common.amercementConfirmedTime':
    'เวลายืนยันการเดบิต',
  'stationMonthlyStatementConfirmation.common.amountPenalty': 'จำนวนเงินที่หัก',
  'stationMonthlyStatementConfirmation.common.amountTo': 'ทั้งหมด',
  'stationMonthlyStatementConfirmation.common.annexI':
    'เอกสารแนบ 1: การชำระกรมธรรม์ระหว่างธุรกิจกับธุรกิจ',
  'stationMonthlyStatementConfirmation.common.annexII':
    'เอกสารแนบ 2: นโยบายการบำรุงรักษาได้รับการแก้ไขแล้ว',
  'stationMonthlyStatementConfirmation.common.annexIII':
    'เอกสารแนบ 3: ตั๋วเงินนำเข้าจากต่างประเทศ',
  'stationMonthlyStatementConfirmation.common.annexIV':
    'เอกสารแนบ 4: รายละเอียดการหักเงิน',
  'stationMonthlyStatementConfirmation.common.annexV':
    'ภาคผนวก 5: การหักเงินที่นำเข้าจากต่างประเทศ',
  'stationMonthlyStatementConfirmation.common.annexVI':
    'เอกสารแนบ 6: รายละเอียดคลังสินค้าของชิ้นส่วนอุปทานโดยตรง',
  'stationMonthlyStatementConfirmation.common.annexVII':
    'เอกสารแนบ 7: รายละเอียดการจัดส่งโดยละเอียดของชิ้นส่วนการจัดหาโดยตรง (รวมเฉพาะ "การหักเงินและการคืนสินค้า")',
  'stationMonthlyStatementConfirmation.common.approvalTime': 'เวลาอนุมัติ',
  'stationMonthlyStatementConfirmation.common.collect': 'สรุป',
  'stationMonthlyStatementConfirmation.common.commissionAmount':
    'จำนวนค่าคอมมิชชั่น',
  'stationMonthlyStatementConfirmation.common.deductAmount': 'จำนวนเงินที่หัก',
  'stationMonthlyStatementConfirmation.common.deductRemark':
    'หมายเหตุการหักเงิน',
  'stationMonthlyStatementConfirmation.common.directSupplyFittingDecreaseInventoryTotalPrice':
    'จำนวนเงินทั้งหมด',
  'stationMonthlyStatementConfirmation.common.directSupplyFittingDiscountFee':
    'หักจำนวนอุปกรณ์เสริม',
  'stationMonthlyStatementConfirmation.common.directSupplyFittingFee':
    'จำนวนอุปกรณ์เสริมที่ได้รับ',
  'stationMonthlyStatementConfirmation.common.directSupplyFittingIncreaseInventoryPurchaseExpenseOffsetTotalPrice':
    'จำนวนเงินที่หักสำหรับชิ้นส่วนอุปทานโดยตรง',
  'stationMonthlyStatementConfirmation.common.directSupplyFittingIncreaseInventoryPurchaseTotalPrice':
    'จำนวนซื้อชิ้นส่วนอุปทานโดยตรง',
  'stationMonthlyStatementConfirmation.common.externalMaintOrderNo':
    'หมายเลขติดตามภายนอก',
  'stationMonthlyStatementConfirmation.common.fittingName': 'ชื่ออุปกรณ์เสริม',
  'stationMonthlyStatementConfirmation.common.historyAmercementFee':
    'การหักยกยอดยกมาจากเดือนที่แล้ว',
  'stationMonthlyStatementConfirmation.common.inventoryQuantity':
    'ปริมาณขาเข้า',
  'stationMonthlyStatementConfirmation.common.maintOrderNo':
    'เลขที่กรมธรรม์การบำรุงรักษา',
  'stationMonthlyStatementConfirmation.common.marginAmountDeduct':
    'จำนวนเงินฝากที่จะหัก',
  'stationMonthlyStatementConfirmation.common.model': 'แบบอย่าง',
  'stationMonthlyStatementConfirmation.common.orderCompletionTime':
    'เวลาเสร็จสิ้นการสั่งซื้อ',
  'stationMonthlyStatementConfirmation.common.orderNumber': 'หมายเลขคำสั่งซื้อ',
  'stationMonthlyStatementConfirmation.common.outboundDate': 'วันที่จัดส่ง',
  'stationMonthlyStatementConfirmation.common.outboundQuantity': 'ปริมาณขาออก',
  'stationMonthlyStatementConfirmation.common.partOne':
    'ไซต์นี้ ({stationName}) ยืนยันว่า ณ วันที่ {statementEndDate} ยอดรวมของค่าธรรมเนียมการซ่อมที่ Xingwei จะต้องชำระคือ {repairFeeUnsettled}{unit} และไม่มีเอกสารการซ่อมที่ค้างชำระ',
  'stationMonthlyStatementConfirmation.common.partThree':
    'ไซต์นี้ขอยืนยันความถูกต้องของสถานะการบำรุงรักษาจริงและค่าใช้จ่ายตามเอกสารแนบ',
  'stationMonthlyStatementConfirmation.common.partTwo':
    'บริษัทของคุณจะไม่รับผิดชอบและชำระค่าซ่อมแซมที่เกิดขึ้นก่อนวันครบกำหนดนอกคำชี้แจงข้อตกลงนี้',
  'stationMonthlyStatementConfirmation.common.payCycle': 'รอบการชำระเงิน:',
  'stationMonthlyStatementConfirmation.common.postingDate': 'วันที่บันทึก',
  'stationMonthlyStatementConfirmation.common.repairFeeSettled':
    'จำนวนเงินที่ชำระแล้ว',
  'stationMonthlyStatementConfirmation.common.repairFeeUnsettled':
    'จำนวนเงินที่จะชำระ',
  'stationMonthlyStatementConfirmation.common.seal': 'แสตมป์',
  'stationMonthlyStatementConfirmation.common.settleAmount': 'จำนวนเงินที่ชำระ',
  'stationMonthlyStatementConfirmation.common.settleEndDate':
    '{settleEndDate} ที่แล้ว',
  'stationMonthlyStatementConfirmation.common.settlementRate':
    'อัตราส่วนการชำระบัญชี',
  'stationMonthlyStatementConfirmation.common.theAmountActuallyPaid':
    'จำนวนเงินที่ชำระจริง:',
  'stationMonthlyStatementConfirmation.common.theAmountAnNengPaid':
    'จำนวนเงินที่ชำระจริง:',
  'stationMonthlyStatementConfirmation.common.theAmountOtherPaid':
    'จำนวนเงินจริงที่ทีมอื่นจ่าย:',
  'stationMonthlyStatementConfirmation.common.title':
    'หนังสือยืนยันค่าบำรุงรักษายานพาหนะ',
  'stationMonthlyStatementConfirmation.common.totalPurchasePrice':
    'ราคาซื้อรวม',
  'stationMonthlyStatementConfirmation.common.unitPurchasePrice':
    'ซื้อราคาต่อหน่วย',
  'stationMonthlyStatementConfirmation.common.whetherToDeduct':
    'ไม่ว่าจะหักหรือไม่ก็ตาม',
  'stationMonthlyStatementConfirmation.common.xingwei':
    'Xingwei Automotive Technology (Wuxi) Co., Ltd.:',
};
