export default {
  'vehicle.team.components.managementVehiclesNumber':
    'จำนวนรถที่อยู่ภายใต้การบริหาร',
  'vehicle.team.components.maintAmountLt': 'สถิติค่าบำรุงรักษา',
  'vehicle.team.components.nonMaintAmountLt': 'สถิติต้นทุนการไม่บำรุงรักษา',
  'vehicle.team.components.maintUncoveredAmountLt':
    'ค่าใช้จ่ายนอกการรับประกันทั้งหมด',
  'vehicle.team.components.maintUncoveredQuotationConfirmValidate':
    'หากต้องการเปิดตามเงื่อนไข ต้องกำหนดค่าอย่างน้อยหนึ่งรายการ',
  'vehicle.team.components.vehicleGroupName': 'กรุณากรอกชื่อกลุ่ม',
  'vehicle.team.components.leaderUserIds':
    'กรุณาเลือกหัวหน้าทีมอย่างน้อยหนึ่งคน',
  'vehicle.team.components.repairApprovalSettings':
    'โปรดเลือกประเภทการอนุมัติการซ่อมแซม',
  'vehicle.team.components.repairAutoDispatch':
    'กรุณาเลือกประเภทการจัดส่งอัตโนมัติ',
  'vehicle.team.components.conditionDesc': 'คำอธิบายสภาพ',
  'vehicle.team.components.amountLimited': 'จำกัดจำนวนเงิน',
  'vehicle.team.components.confirmCondition':
    'สามารถกำหนดค่าได้หลายเงื่อนไขผ่านการเลือกหลายรายการ หากตรงตามเงื่อนไขข้อใดข้อหนึ่ง กระบวนการยืนยันจะเริ่มต้นขึ้น',
};
