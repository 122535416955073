import { defineStore } from 'pinia';

import { permissionKeyList, userInfo } from '@/api/user';

import { allRoutes } from '@/router/index.js';

import {
  filterMenuRoutes,
  filterMenuRoutesByAgencyType,
  filterTargetMenuRoutes,
} from '@/utils/utils-route.js';
import { flatTree } from '@/utils/index.js';
import {
  getAccessToken,
  setAccessToken as setAT,
  removeAccessToken as removeAT,
} from '@/utils/accessToken';
import { isEmpty } from '@/utils/utils-validator';

const state = () => {
  return {
    accessToken: getAccessToken(),
    userInfo: isEmpty(localStorage.getItem('userInfo'))
      ? {}
      : JSON.parse(localStorage.getItem('userInfo')),
    menus: [], // 用户导航菜单
    oftenMenus: [], // 顶部常用菜单
    agencyTypeMenus: [], // 机构导航菜单
    agencyType: '', // 用户类型
    permissionKeys: [], //权限key
  };
};

const getters = {
  qiniu: (state) => {
    return state.userInfo?.locations === 'MYS' ||
      state.userInfo?.locations === 'PHL' ||
      state.userInfo?.locations === 'THA'
      ? {
          uploadUrl: import.meta.env.VITE_APP_QINIU_UPLOAD_URL_MYS,
          cdnUrl: import.meta.env.VITE_APP_QINIU_CDN_MYS,
        }
      : {
          uploadUrl: import.meta.env.VITE_APP_QINIU_UPLOAD_URL_CHN,
          cdnUrl: import.meta.env.VITE_APP_QINIU_CDN_CHN,
        };
  },
};

const actions = {
  setAccessToken(token) {
    this.accessToken = token;
    setAT(token);
  },
  removeAccessToken() {
    this.accessToken = null;
    this.menus = [];
    this.oftenMenus = [];
    removeAT();
  },
  setUserInfo(userInfo) {
    this.userInfo = userInfo;
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  },
  setMenus(menus) {
    this.menus = [...menus];
  },
  setOftenMenus(menus) {
    this.oftenMenus = [...menus];
  },
  setAgencyTypeMenus(menus) {
    this.agencyTypeMenus = [...menus];
  },
  setAgencyType(agencyType) {
    this.agencyType = agencyType;
  },
  setPermissionKeys(permissionKeys) {
    this.permissionKeys = permissionKeys;
  },
  async getPermissionKeys() {
    const { permissionKeys } = await permissionKeyList();
    this.setPermissionKeys(permissionKeys);
    return permissionKeys;
  },
  async updateMenusAndUserInfo() {
    if (!this.accessToken) {
      return;
    }

    const { agencyType, permissionKeys } = await permissionKeyList();

    // 设置机构菜单
    const agencyTypeMenus = filterMenuRoutesByAgencyType(
      filterMenuRoutes(allRoutes),
      agencyType,
    );

    // 设置用户菜单
    const menus = filterTargetMenuRoutes(
      JSON.parse(JSON.stringify(agencyTypeMenus)),
      permissionKeys,
    );

    // 设置常用菜单
    const OftenMenuListMap = {
      PLATFORM: ['MAINT_MENUS', 'VEHICLE_MANAGE', 'SERVICE_STATION_MANAGE'],
      STATION: ['MAINT_MENUS', 'DIRECT_SUPPLY_FITTING_VIEW'],
      VEHICLE_TEAM: ['MAINT_MENUS', 'VEHICLE_MANAGE'],
    };
    const oftenMenuRoutes = filterTargetMenuRoutes(
      JSON.parse(JSON.stringify(agencyTypeMenus)),
      OftenMenuListMap[agencyType],
    );
    const oftenMenus = flatTree(oftenMenuRoutes).filter(
      (route) =>
        route.meta?.permissionKeyWithRoute &&
        permissionKeys.includes(route.meta.permissionKeyWithRoute.key),
    );

    // 设置菜单
    this.setMenus(menus);
    this.setOftenMenus(oftenMenus);
    this.setAgencyTypeMenus(agencyTypeMenus);

    // 设置用户类型
    this.setAgencyType(agencyType);
    // 设置用户权限
    this.setPermissionKeys(permissionKeys);
  },
  async getUserInfo() {
    const data = await userInfo();
    this.setUserInfo(data);
    return data;
  },
};

export const useUserStore = defineStore('user', {
  state,
  getters,
  actions,
});
