export default {
  'vehicleTeamMonthlyInspectionReport.components.templateItems':
    '{total}รายการ',

  'vehicleTeamMonthlyInspectionReport.components.reportId': 'รหัสรายงาน',
  'vehicleTeamMonthlyInspectionReport.components.reportTime': 'เวลารายงาน',
  'vehicleTeamMonthlyInspectionReport.components.vehiclesInspectedNo':
    'ตรวจสอบจำนวนยานพาหนะ',
  'vehicleTeamMonthlyInspectionReport.components.inspectionServiceNo':
    'จำนวนบริการตรวจสภาพรถ',
  'vehicleTeamMonthlyInspectionReport.components.inspectionItemNo':
    'จำนวนรายการตรวจสอบ',
  'vehicleTeamMonthlyInspectionReport.components.abnormalItemNo':
    'จำนวนรายการที่ผิดปกติ',
  'vehicleTeamMonthlyInspectionReport.components.inspectionDate':
    'เวลาตรวจสภาพรถ',
  'vehicleTeamMonthlyInspectionReport.components.vehiclesWithAbnormalsNo':
    'จำนวนรถที่ผิดปกติ',
  'vehicleTeamMonthlyInspectionReport.components.percentageOfAbnormalVehicles':
    'สัดส่วนของยานพาหนะที่ผิดปกติ',
  'vehicleTeamMonthlyInspectionReport.components.inspectionNo': 'ตรวจสอบเวลา',
  'vehicleTeamMonthlyInspectionReport.components.mainHiddenAbnormals':
    'อันตรายและความผิดปกติที่ซ่อนอยู่หลัก',
  'vehicleTeamMonthlyInspectionReport.components.inspectionItems':
    'ตรวจสอบรายการ',
  'vehicleTeamMonthlyInspectionReport.components.abnormals': 'สถานการณ์ผิดปกติ',
  'vehicleTeamMonthlyInspectionReport.components.inspectionMedia':
    'ตรวจสอบรูปภาพ/วิดีโอ',
  'vehicleTeamMonthlyInspectionReport.components.totalFaultItems':
    'รายการข้อบกพร่องทั้งหมด',
};
