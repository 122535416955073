export default {
  'vehicleInspectionReport.components.reportStartTime': 'รายงานเวลาเริ่มต้น',
  'vehicleInspectionReport.components.reportId': 'รหัสรายงาน',
  'vehicleInspectionReport.components.inspectionType': 'ประเภทการตรวจสภาพรถ',
  'vehicleInspectionReport.components.inspectionDate': 'วันที่ตรวจสภาพรถ',
  'vehicleInspectionReport.components.reportTime': 'เวลารายงาน',
  'vehicleInspectionReport.components.inspectionStatistics':
    'สถิติการตรวจสภาพรถ',
  'vehicleInspectionReport.components.abnormals': 'สถานะการตรวจสภาพรถ',
  'vehicleInspectionReport.components.inspectionItems': 'ตรวจสอบรายการ',
  'vehicleInspectionReport.components.inspectionResult': 'ตรวจสอบผลลัพธ์',
  'vehicleInspectionReport.components.inspectionMedia': 'ตรวจสอบรูปภาพ/วิดีโอ',
  'vehicleInspectionReport.components.inspectionLocation': 'สถานที่ตรวจสภาพรถ',
};
