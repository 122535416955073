export default {
  'app.common.account': 'บัญชี',
  'app.common.add': 'ใหม่',
  'app.common.additionalDiapatch': 'ซ่อมแซม',
  'app.common.additionalDispatch': 'สั่งเปลี่ยน',
  'app.common.anNengFittingModelMappingExport':
    'Aneng Xingwei ชั่วโมงการทำงานอุปกรณ์เสริมการทำแผนที่ส่งออก',
  'app.common.anNengFittingModelMappingImport':
    'Aneng Xingwei ชั่วโมงการทำงานนำเข้าการทำแผนที่อุปกรณ์เสริม',
  'app.common.appUploadAction': 'อัปโหลด',
  'app.common.appUploadAttachmentFailedAgainMsg':
    'การอัปโหลดไฟล์แนบล้มเหลว โปรดลองอีกครั้ง',
  'app.common.appUploadAttachmentFailedMsg': 'การอัปโหลดไฟล์แนบล้มเหลว {msg}',
  'app.common.appUploadAttachmentFileRestriction':
    'ไฟล์แนบที่อัปโหลดต้องไม่เป็นไฟล์เดียวกัน',
  'app.common.appUploadAttachmentSizeRestriction':
    'ขนาดของไฟล์ที่อัพโหลดต้องไม่เกิน {size}M',
  'app.common.appUploadAttachmentTypeRestriction':
    'ประเภทของไฟล์ที่อัพโหลดต้องเป็นประเภทต่อไปนี้เท่านั้น {types}',
  'app.common.appUploadImageFailedAgainMsg':
    'การอัปโหลดรูปภาพล้มเหลว โปรดลองอีกครั้ง',
  'app.common.appUploadImageFailedMsg': 'การอัปโหลดรูปภาพล้มเหลว: {msg}',
  'app.common.appUploadImageFileRestriction':
    'รูปภาพที่อัปโหลดต้องไม่ใช่รูปภาพเดียวกัน',
  'app.common.appUploadImageQuantityRestriction':
    'จำนวนภาพที่อัปโหลดต้องไม่เกิน {limitTotal}',
  'app.common.appUploadImageSizeRestriction':
    'ขนาดของภาพที่อัพโหลดต้องไม่เกิน {size}M',
  'app.common.appUploadImageTypeRestriction':
    'ประเภทของภาพที่อัพโหลดต้องเป็นประเภทต่อไปนี้เท่านั้น {types}',
  'app.common.available': 'มีอยู่',
  'app.common.basic': 'ขั้นพื้นฐาน',
  'app.common.basicInformation': 'ข้อมูลพื้นฐาน',
  'app.common.billing': 'ใบเรียกเก็บเงินค่าใช้จ่าย',
  'app.common.brand': 'ยี่ห้อ',
  'app.common.browsersNotSupportTag': 'เบราว์เซอร์ของคุณไม่รองรับแท็ก {tag}',
  'app.common.businessDataExport': 'การส่งออกข้อมูล',
  'app.common.businessDataImport': 'การนำเข้าข้อมูล',
  'app.common.callingCode': 'รหัสพื้นที่',
  'app.common.cancel': 'ยกเลิก',
  'app.common.china': 'จีน',
  'app.common.close': 'ปิด',
  'app.common.colon': '-',
  'app.common.confirm': 'แน่นอน',
  'app.common.confirmToDelete': 'คุณแน่ใจหรือไม่ว่าต้องการลบมัน?',
  'app.common.confirmWithSpace': 'แน่นอน',
  'app.common.contactMobile': 'เบอร์ติดต่อ',
  'app.common.contactPerson': 'ผู้ติดต่อ',
  'app.common.contractedStation': 'สถานีลงนาม',
  'app.common.cooperativeStation': 'สถานีความร่วมมือ',
  'app.common.data': 'ข้อมูล',
  'app.common.DataExportingDescription':
    'กำลังส่งออกข้อมูล โปรดกลับมาที่หน้านี้ภายในไม่กี่นาที และคลิกปุ่มดาวน์โหลดทางด้านขวาของการส่งออกเพื่อดาวน์โหลดไฟล์',
  'app.common.dataProcess': 'การประมวลผลข้อมูล',
  'app.common.delete': 'ลบ',
  'app.common.details': 'รายละเอียด',
  'app.common.directSupplyFittingView': 'การจัดการชิ้นส่วนอุปทานโดยตรง',
  'app.common.disable': 'ปิดการใช้งาน',
  'app.common.dispatch': 'จัดส่งคำสั่งซื้อ',
  'app.common.distributionCenterFittingClaimOrder': 'เครื่องประดับ',
  'app.common.distributionCenterManage': 'การจัดการจุดจำหน่าย',
  'app.common.distributionCenterOperate': 'การดำเนินการจุดจัดส่ง',
  'app.common.download': 'ดาวน์โหลด',
  'app.common.edit': 'แก้ไข',
  'app.common.email': 'จดหมาย',
  'app.common.enable': 'เปิดใช้งาน',
  'app.common.endTime': 'เวลาสิ้นสุด',
  'app.common.english': 'ภาษาอังกฤษ',
  'app.common.thai': 'ไทย',
  'app.common.expand': 'ขยาย',
  'app.common.export': 'ส่งออก',
  'app.common.exporting': 'กำลังส่งออก',
  'app.common.exportMaintSettlementOrder': 'การส่งออกโดยละเอียด',
  'app.common.exportPlatformExternalBillingOrder': 'รายละเอียดการส่งออก',
  'app.common.exportStationExternalBillingOrder': 'รายละเอียดการส่งออก',
  'app.common.externalBilling': 'บิลภายนอก',
  'app.common.faultManage': 'ห้องสมุดข้อผิดพลาด',
  'app.common.faultManageEdit': 'สิทธิ์ในการดำเนินการไลบรารีข้อผิดพลาด',
  'app.common.filter': 'กรอง',
  'app.common.finance': 'การเงิน',
  'app.common.fitting': 'เครื่องประดับ',
  'app.common.fittingManage': 'ห้องสมุดอุปกรณ์เสริม',
  'app.common.fittingManageEdit': 'สิทธิ์ในการดำเนินการไลบรารีอุปกรณ์เสริม',
  'app.common.fittingModelManage': 'ห้องสมุดโมเดล',
  'app.common.fittingModelManageEdit': 'สิทธิ์ในการดำเนินการไลบรารีโมเดล',
  'app.common.fittingShoppingMall': 'ห้างสรรพสินค้าอุปกรณ์เสริม',
  'app.common.fleetOwnershipEdit': 'แก้ไขการเป็นเจ้าของยานพาหนะ',
  'app.common.forgotPassword': 'ดึงรหัสผ่าน',
  'app.common.fullName': 'ชื่อ',
  'app.common.idle': 'ไม่ได้ใช้งาน',
  'app.common.import': 'นำเข้า',
  'app.common.importMaintSettlementInfo': 'นำเข้าสถานะการเรียกเก็บเงิน',
  'app.common.importPlatformExternalBilling': 'นำเข้าบิลภายนอก',
  'app.common.inspect': 'ตรวจสอบ',
  'app.common.iSee': 'ฉันเห็น',
  'app.common.item': 'รายการ',
  'app.common.keep': 'เก็บ',
  'app.common.latestExportTime': 'เวลาส่งออกล่าสุด',
  'app.common.loading': 'กำลังโหลด',
  'app.common.logout': 'ล้มเลิก',
  'app.common.maint': 'การซ่อมบำรุง',
  'app.common.maintOrderAssign': 'มอบหมายให้พนักงาน',
  'app.common.maintOrderAuditConfig': 'การตั้งค่าการอนุมัตินโยบายการบำรุงรักษา',
  'app.common.maintOrderCheckin': 'ขึ้นรถ',
  'app.common.maintOrderNo': 'เลขที่กรมธรรม์การบำรุงรักษา',
  'app.common.maintOrderQuote': 'อ้าง',
  'app.common.maintOrderQuoteAudit': 'การอนุมัติใบเสนอราคา',
  'app.common.maintOrderReceive': 'รับออเดอร์',
  'app.common.maintOrderRecheck': 'ทบทวน',
  'app.common.maintOrderRecheckDispatchedExport': 'เอกสารส่งออกที่จะตรวจสอบ',
  'app.common.maintOrderRecheckDispatchedImport': 'นำเข้าเอกสารที่จะตรวจสอบ',
  'app.common.maintOrderRecord': 'บันทึกคำสั่ง',
  'app.common.maintOrderRecordDelegate': 'สลิปการรับสมัคร',
  'app.common.maintOrderRemarkCreate': 'หมายเหตุนโยบายการบำรุงรักษา',
  'app.common.maintOrderReplaceAuditor': 'เปลี่ยนผู้อนุมัติ',
  'app.common.maintOrderRevoke': 'เป็นโมฆะ',
  'app.common.maintOrderStationChange': 'เปลี่ยนไซต์',
  'app.common.maintOrderTransfer': 'โอนย้าย',
  'app.common.maintOrderViewAll': 'ดูนโยบายการบำรุงรักษาทั้งหมด',
  'app.common.maintOrderViewApprovedUser': 'ดูข้อมูลผู้ประมูลที่ได้รับอนุมัติ',
  'app.common.maintPlan': 'แผนการบำรุงรักษา',
  'app.common.maintRequestImport': 'การบำรุงรักษาเป็นชุด',
  'app.common.maintSettlementOrder': 'ใบเรียกเก็บเงินการชำระบัญชี',
  'app.common.maintUncoveredBilling': 'ออกบิลประกันตัวแล้ว',
  'app.common.maintUncoveredBillingExportDetail': 'การส่งออกโดยละเอียด',
  'app.common.maintUncoveredBillingImportReconciliation':
    'สถานะการกระทบยอดการนำเข้า',
  'app.common.maintUncoveredBillingImportSettlement':
    'นำเข้าสถานะการเรียกเก็บเงิน',
  'app.common.maintUncoveredCostInformAudit': 'ตรวจสอบค่าใช้จ่ายในการช่วยเหลือ',
  'app.common.maintUncoveredCostInformSubmit':
    'เริ่มต้นการยืนยันค่าธรรมเนียมที่ไม่อยู่ภายใต้การรับประกัน',
  'app.common.malaysia': 'มาเลเซีย',
  'app.common.menu': 'เมนู',
  'app.common.menuCommission': 'การตั้งค่าคอมมิชชั่น',
  'app.common.menuInventory': 'สินค้าคงคลังของสถานีบริการ',
  'app.common.menuProduct': 'การจัดการผลิตภัณฑ์',
  'app.common.menuPurchaseOrders': 'ใบสั่งซื้อ',
  'app.common.menuSalesOrders': 'ใบสั่งขาย',
  'app.common.menuVendor': 'การจัดการซัพพลายเออร์',
  'app.common.mobileNumber': 'หมายเลขโทรศัพท์',
  'app.common.more': 'มากกว่า',
  'app.common.multipleChoice': 'หลายทางเลือก',
  'app.common.newPassword': 'รหัสผ่านใหม่',
  'app.common.noData': 'ยังไม่มีข้อมูล',
  'app.common.noPermission': 'ยังไม่มีการอนุญาต',
  'app.common.notification': 'คำใบ้',
  'app.common.number': 'ตัวเลข',
  'app.common.ok': 'ยืนยัน',
  'app.common.okText': 'แน่นอน',
  'app.common.oldPassword': 'รหัสผ่านเก่า',
  'app.common.operate': 'ดำเนินงาน',
  'app.common.operateType': 'ประเภทการดำเนินงาน',
  'app.common.operation': 'ดำเนินงาน',
  'app.common.operator': 'ตัวดำเนินการ',
  'app.common.password': 'รหัสผ่าน',
  'app.common.permissionManage': 'การจัดการบทบาท',
  'app.common.philippines': 'ฟิลิปปินส์',
  'app.common.phraseManage': 'การจัดการแท็ก',
  'app.common.plateNo': 'หมายเลขป้ายทะเบียน',
  'app.common.productionDataProcess': 'การประมวลผลข้อมูลการผลิต',
  'app.common.property': 'สินทรัพย์',
  'app.common.remark': 'หมายเหตุ',
  'app.common.remove': 'ลบ',
  'app.common.repair': 'สร้าง',
  'app.common.report': 'รายงาน',
  'app.common.resetWithSpace': 'รีเซ็ต',
  'app.common.revokeAfterQuoteAccepted':
    'ถือเป็นโมฆะนโยบายการบำรุงรักษาที่ได้รับอนุมัติ',
  'app.common.revokeAfterQuoteAcceptedSnapshot':
    'บันทึกนโยบายการบำรุงรักษาที่ได้รับอนุมัติเป็นโมฆะ',
  'app.common.riskControlLog': 'บันทึกการควบคุมความเสี่ยง',
  'app.common.save': 'บันทึก',
  'app.common.search': 'ค้นหา',
  'app.common.selectFromTheLeftFirst': 'เลือกจากทางซ้ายก่อน',
  'app.common.sendSms': 'รับรหัสยืนยัน',
  'app.common.serviceStationManage': 'การจัดการสถานีบริการ',
  'app.common.serviceStationOperate': 'สิทธิการดำเนินงานอื่น ๆ ของสถานีบริการ',
  'app.common.serviceStationViewAll': 'ดูสิทธิ์ของสถานีบริการทั้งหมด',
  'app.common.set': 'ตั้งค่า',
  'app.common.shenTongSettledVoucherSetting':
    'การตั้งค่าบัตรกำนัลการชำระบัญชี STO',
  'app.common.shinwell': 'การบำรุงรักษารถยนต์ซิงเว่ย',
  'app.common.simplifiedChinese': 'จีนตัวย่อ',
  'app.common.sms': 'รหัสยืนยัน',
  'app.common.snapshot': 'บันทึก',
  'app.common.snapshotId': 'สแนปชอต',
  'app.common.sort': 'เรียงลำดับ',
  'app.common.startTime': 'เวลาเริ่มต้น',
  'app.common.stationAmercement': 'การหักเงิน',
  'app.common.stationAmercementBatchCreate':
    'การหักเงินสถานีบริการนำเข้า (เพื่อวัตถุประสงค์ทางการเงินเท่านั้น)',
  'app.common.stationAmercementDetail': 'รายละเอียดการหักเงิน',
  'app.common.stationAmercementView': 'ดูการหักเงิน',
  'app.common.stationBalanceDeduction':
    'การดำเนินการหักยอดคงเหลือกระเป๋าเงินของสถานีบริการ',
  'app.common.stationBalanceDeductionSnapshot':
    'บันทึกการหักยอดคงเหลือกระเป๋าสตางค์ของสถานีบริการ',
  'app.common.stationBatchMaintButter': 'เนยบำรุงรักษาแบบแบตช์',
  'app.common.stationConfirmReceivedMarginPayment': 'การยืนยันการรับเงินฝาก',
  'app.common.stationDsfittingInventoryOperate':
    'การจัดการสินค้าคงคลังการจัดหาชิ้นส่วนโดยตรง',
  'app.common.stationFittingPriceOperate': 'อำนาจดำเนินการราคาอุปกรณ์เสริม',
  'app.common.stationFittingShoppingMall': 'ห้างสรรพสินค้าอุปกรณ์สถานีบริการ',
  'app.common.stationFittingShoppingMallBlankTip':
    'โปรดไปที่ไคลเอนต์มือถือเพื่อดูเนื้อหา',
  'app.common.stationMonthlyStatement': 'คำแถลง',
  'app.common.stationMonthlyStatementCommitInvoice':
    'ส่งใบสำคัญการเรียกเก็บเงิน',
  'app.common.stationMonthlyStatementConfirmDownload':
    'ดาวน์โหลดหนังสือยืนยันใบแจ้งยอด',
  'app.common.stationMonthlyStatementFeeImport':
    'ค่าใช้จ่ายในการเดินบัญชีการนำเข้า',
  'app.common.stationMonthlyStatementPlatformConfirm': 'การยืนยันคำชี้แจง',
  'app.common.stationMonthlyStatementPlatformExport': 'การส่งออกใบแจ้งยอด',
  'app.common.stationMonthlyStatementRegenerate':
    'ใบแจ้งหนี้ไม่ถูกต้องและสร้างใหม่',
  'app.common.stationMonthlyStatementSettle': 'การชำระบัญชีใบแจ้งยอด',
  'app.common.stationSignContractConfirm': 'ลงนามยืนยัน',
  'app.common.stationSuspendResumeDispatch':
    'หยุดชั่วคราวและดำเนินการจัดส่งต่อ',
  'app.common.stationWorkinghourPriceOperate':
    'หน่วยงานดำเนินการราคารายชั่วโมง',
  'app.common.status': 'สถานะ',
  'app.common.success': 'ความสำเร็จ',
  'app.common.system': 'ระบบ',
  'app.common.systemLog': 'บันทึกของระบบ',
  'app.common.systemSetting': 'การตั้งค่าระบบ',
  'app.common.templateCharacterLength': '{label} ต้องเป็นอักขระ {len}',
  'app.common.templateCharacterMax': '{label} สูงสุด {max} อักขระ',
  'app.common.templateCharacterMin': '{label}ขั้นต่ำ {min} ตัวอักษร',
  'app.common.templateCharacterRange':
    '{label} ต้องอยู่ระหว่าง {min}-{max} อักขระ',
  'app.common.templateHasSave': 'บันทึก {column} แล้ว',
  'app.common.templatePictures': '{total} รูปภาพ',
  'app.common.templatePlaceholder': 'กรุณากรอก {label}',
  'app.common.templateSelectPlaceholder': 'โปรดเลือก {label}',
  'app.common.templateTotal': 'รวมทั้งหมด {total} รายการ',
  'app.common.templateType': '{label} ต้องเป็น {type}',
  'app.common.templateVideos': '{total} วิดีโอ',
  'app.common.temporaryStation': 'สถานีชั่วคราว',
  'app.common.time': 'เวลา',
  'app.common.tips': 'คำใบ้',
  'app.common.tool': 'เครื่องมือ',
  'app.common.uploadFailedTryAgain': 'การอัปโหลดล้มเหลว โปรดลองอีกครั้ง',
  'app.common.uploadPhotos': 'อัพโหลดรูปภาพ',
  'app.common.user': 'ผู้ใช้',
  'app.common.userManage': 'การจัดการบัญชี',
  'app.common.username': 'ชื่อผู้ใช้',
  'app.common.userSpecialDutyRosterExport': 'กำหนดการส่งออก',
  'app.common.userSpecialDutyRosterImport': 'กำหนดการนำเข้า',
  'app.common.utilityTools': 'เครื่องมืออรรถประโยชน์',
  'app.common.vehicleDetailImport': 'รายละเอียดการนำเข้า',
  'app.common.vehicleDriverImport': 'นำเข้าข้อมูลไดรเวอร์',
  'app.common.vehicleDriverMaintain': 'พนักงานขับรถซ่อมบำรุง',
  'app.common.vehicleEffectiveAndIneffective':
    'ทำเครื่องหมายว่าไม่ถูกต้องและกู้คืนยานพาหนะ',
  'app.common.vehicleGroupManage': 'การจัดการลูกเรือยานพาหนะ',
  'app.common.vehicleGroupStationMaintUncoveredPipExport':
    'การส่งออกอัตราส่วนราคาเพิ่มการรับประกันพิเศษ',
  'app.common.vehicleGroupStationMaintUncoveredPipImport':
    'การนำเข้าอัตราส่วนการเพิ่มราคาที่ไม่อยู่ภายใต้การรับประกัน',
  'app.common.vehicleImport': 'นำเข้าข้อมูลใบขับขี่',
  'app.common.vehicleInspectionReport': 'รายละเอียดรายงานการตรวจสภาพรถ',
  'app.common.vehicleLicenseAdd': 'เพิ่มยานพาหนะใหม่',
  'app.common.vehicleLicenseEdit': 'รักษาใบอนุญาตรถ',
  'app.common.vehicleLicImgImport': 'อัพโหลด/อัพเดตใบขับขี่ (ธุรกิจกองเรือ)',
  'app.common.vehicleMaintCoverHistoryEdit':
    'บันทึกความครอบคลุมการอัปเดตเป็นชุด',
  'app.common.vehicleManage': 'การจัดการยานพาหนะ',
  'app.common.vehicleMileage': 'ระยะทางของยานพาหนะ',
  'app.common.vehicleMileageCalibration': 'การสอบเทียบด้วยตนเอง',
  'app.common.vehicleMileageExport': 'การส่งออกไมล์สะสม',
  'app.common.vehicleMileageMonthlyConfirm': 'การยืนยันระยะทางรายเดือน',
  'app.common.vehicleMileageMonthlyReconcile': 'การกระทบยอดรายเดือน',
  'app.common.vehicleMileageStatement': 'การกระทบยอดระยะทาง',
  'app.common.vehicleServiceInfoImport': 'นำเข้าข้อมูลบริการ',
  'app.common.vehicleTeam': 'กองทัพเรือ',
  'app.common.vehicleTeamGroupAssociated': 'ยานพาหนะที่เกี่ยวข้อง',
  'app.common.vehicleTeamGroupEdit': 'ทีมทีม',
  'app.common.vehicleTeamGroupImport': 'อัปเดตทีม',
  'app.common.vehicleTeamMaintUncoveredOfferPriceExport':
    'ราคาส่งออกของข้อตกลงที่ไม่รับประกัน',
  'app.common.vehicleTeamMaintUncoveredOfferPriceImport':
    'การนำเข้าราคาตามสัญญาที่ไม่รับประกัน',
  'app.common.vehicleTeamMaintUncoveredPipOperate':
    'อัตราส่วนมาร์กอัปใบเสนอราคา',
  'app.common.vehicleTeamManage': 'การจัดการยานพาหนะ',
  'app.common.vehicleTeamMonthlyInspectionReport':
    'รายงานการตรวจสภาพรถประจำเดือน',
  'app.common.vehicleTeamOperate': 'อำนาจปฏิบัติการกองเรือ',
  'app.common.workinghourManage': 'ห้องสมุดเวลา',
  'app.common.workinghourManageEdit': 'สิทธิ์ในการดำเนินการห้องสมุดเวลา',
};
