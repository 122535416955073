export default {
  'vehicleTeamMonthlyInspectionReport.common.viewReport': 'ดูรายงาน',
  'vehicleTeamMonthlyInspectionReport.common.forMoreItems':
    'สำหรับข้อมูลเพิ่มเติมกรุณาไปที่คอมพิวเตอร์เพื่อดู',
  'vehicleTeamMonthlyInspectionReport.common.otherForMoreItems':
    'อื่นๆ (สำหรับรายละเอียดเพิ่มเติมของรายการตรวจสอบ โปรดเข้าสู่ระบบคอมพิวเตอร์เพื่อดู)',
  'vehicleTeamMonthlyInspectionReport.common.inspectionItems':
    'รายการตรวจสอบหลัก',
  'vehicleTeamMonthlyInspectionReport.common.inspectionAbnormalStatistics':
    'ตรวจสอบสถิติข้อยกเว้น',
  'vehicleTeamMonthlyInspectionReport.common.detailsOfInspectionAbnormals':
    'รายละเอียดข้อยกเว้นการตรวจสอบยานพาหนะ',
};
