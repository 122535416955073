export default {
  'maint.details.additionalDispatchTips':
    'เหตุผลในการส่ง: {additionalDispatchReason} เพิ่มเวลา: {additionalDispatchTime}',
  'maint.details.allowance': 'เงินอุดหนุน',
  'maint.details.approvedResult': 'ข้อสรุปการอนุมัติ',
  'maint.details.approvePassed': 'ผ่าน',
  'maint.details.approver': 'ผู้อนุมัติปัจจุบัน',
  'maint.details.approveRejected': 'ปฏิเสธ',
  'maint.details.checkinOvertime': 'หมดเวลารับรถ',
  'maint.details.completedDate': 'เวลาเสร็จสิ้น',
  'maint.details.confirmedOrRevokedTime': 'ยืนยัน/ถือเป็นโมฆะเวลา',
  'maint.details.confirmedOrRevokedUser': 'ยืนยัน/เป็นโมฆะบุคคล',
  'maint.details.exceedRatio': 'ไม่สมส่วน',
  'maint.details.feeAmount': 'จำนวนเงิน ({currencySymbol})',
  'maint.details.feeItem': 'รายการค่าใช้จ่าย',
  'maint.details.feePrice': 'ราคาต่อหน่วย ({currencySymbol})',
  'maint.details.feeTotalTitle': 'ค่าใช้จ่ายทั้งหมด',
  'maint.details.feeUnit': 'หน่วย',
  'maint.details.fittingComplianceTip':
    'โปรดเลือกอุปกรณ์เสริมตามความต้องการของทีมงาน หากไม่ปฏิบัติตามข้อกำหนดต่อไปนี้ เอกสารจะไม่ได้รับการอนุมัติ',
  'maint.details.freedAmount': 'จำนวนส่วนลด ({currencySymbol})',
  'maint.details.freedFeeTotal': 'การหักเงินทั้งหมด',
  'maint.details.initiatedSnapshot': 'ภาพรวม (เนื้อหาที่เริ่มต้น)',
  'maint.details.initiatedTime': 'เวลาเปิดตัว',
  'maint.details.initiator': 'สปอนเซอร์',
  'maint.details.job': 'การดำเนินการ',
  'maint.details.log': 'บันทึก',
  'maint.details.maintOrderNo': 'เลขที่กรมธรรม์การบำรุงรักษา',
  'maint.details.maintOrderStatus': 'สถานะ',
  'maint.details.maintRequestDate': 'เวลาสร้าง',
  'maint.details.maintRequestType': 'ประเภทการซ่อม',
  'maint.details.maintReservedAddress': 'สถานที่ซ่อม',
  'maint.details.maintUncoveredConfirmedDetails': 'ยืนยันรายละเอียด',
  'maint.details.maintUncoveredConfirmedVoucher': 'ยืนยันข้อมูลรับรอง',
  'maint.details.maintUncoveredConfirmRecord':
    'บันทึกการยืนยันค่าธรรมเนียมนอกประกัน',
  'maint.details.maintUncoveredConfirmStatus': 'สถานะ',
  'maint.details.maintUncoveredPipAutoCalculateTips':
    'ราคานี้คำนวณจากการเพิ่มราคาอัตโนมัติของ {maintUncoveredPip}%',
  'maint.details.maintUncoveredRemark': 'หมายเหตุเกี่ยวกับการประกันตัว',
  'maint.details.modifyQuotationReasonTips':
    'ราคาที่คำนวณโดยระบบเดิม: {systemCalculateAmount} อัตราส่วนการเพิ่มราคาปัจจุบันคือ {percentage}% เหตุผลในการเปลี่ยนแปลงราคา: {modifyQuotationReason}',
  'maint.details.originalVehicleTeamMaintUncoveredQuotedAmount':
    'ใบเสนอราคากลุ่มรถเดิมที่ไม่อยู่ในการรับประกัน ({currencySymbol})',
  'maint.details.originalVehicleTeamMaintUncoveredQuotedAmountTotal':
    'ใบเสนอราคารวมกลุ่มรถเดิมที่ไม่อยู่ภายใต้การรับประกัน',
  'maint.details.otherFeeTotal': 'รวมค่าใช้จ่ายอื่นๆ',
  'maint.details.overview': 'ภาพรวม',
  'maint.details.quotation': 'อ้าง',
  'maint.details.reason': 'เหตุผล',
  'maint.details.referencePrice': 'ราคาอ้างอิง',
  'maint.details.reservedEndTime': '{dateTime} ที่แล้ว',
  'maint.details.reservedTime': 'เวลานัดหมาย',
  'maint.details.reviewedSnapshot': 'ภาพรวม (เนื้อหาการตรวจสอบ)',
  'maint.details.reviewer': 'ผู้วิจารณ์',
  'maint.details.stationQuotedAmount':
    'ใบเสนอราคาของสถานีบริการ ({currencySymbol})',
  'maint.details.stationQuotedAmountTotal': 'ราคารวมสถานีบริการ',
  'maint.details.stationQuoteDetails': 'ข้อมูลใบเสนอราคาสถานีบริการ',
  'maint.details.thirdPartyQuotedItemTitle': 'ชื่อภายนอก',
  'maint.details.Uncovered': 'ออกประกันตัวแล้ว',
  'maint.details.vehicleMaint': 'การบำรุงรักษายานพาหนะ',
  'maint.details.vehicleTeamAgreementPriceTips':
    'ราคานี้เป็นราคาเรือที่เจรจากันไว้',
  'maint.details.vehicleTeamMaintUncoveredDetails':
    'รายละเอียดใบเสนอราคาที่ไม่อยู่ในการรับประกันของกลุ่มรถ',
  'maint.details.vehicleTeamMaintUncoveredQuotedAmount':
    'ใบเสนอราคาที่ไม่อยู่ภายใต้การรับประกันของกลุ่มยานพาหนะ ({currencySymbol})',
  'maint.details.vehicleTeamMaintUncoveredQuotedAmountTotal':
    'ราคารวมกลุ่มรถที่ไม่อยู่ภายใต้การรับประกัน',
  'maint.details.viewVoucher': 'ดูบัตรกำนัล',
  'maint.details.workinghourFittingTotal': 'ชั่วโมงรวมและอุปกรณ์เสริม',
  'maint.details.workinghourNumberOrCount': 'ชั่วโมง/ปริมาณ',
};
