import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import LocalStorageKey from '@/constants/local-storage-key.js';

import { I18nLanguage } from '@/locales/locales.js';

export default function useLocale() {
  const i18 = useI18n({
    // this is default, however we explicitly set to be obvious
    useScope: 'global',
  });

  /**
   * 获取当前应用设置的语言
   * @returns {string}
   */
  const currentLocale = computed(() => {
    return i18.locale.value;
  });

  /**
   * 设置应用语言环境
   * @param {string} lang 所要设置的语言环境
   */
  const setLocale = (lang) => {
    if (i18.locale.value === lang) {
      return;
    }

    i18.locale.value = lang;
    document.querySelector('html').setAttribute('lang', lang);
    localStorage.setItem(LocalStorageKey.APP_LANGUAGE, lang);
  };

  /**
   * 设置应用语言为中文
   */
  const setLocaleToZhCN = () => {
    setLocale(I18nLanguage.ZH_CN);
  };

  /**
   * 设置应用语言为英文
   */
  const setLocaleToEnUS = () => {
    setLocale(I18nLanguage.EN_US);
  };

  /**
   * 设置应用语言为泰文
   */
  const setLocaleToThTH = () => {
    setLocale(I18nLanguage.TH_TH);
  };

  return {
    currentLocale,
    setLocale,
    setLocaleToZhCN,
    setLocaleToEnUS,
    setLocaleToThTH,
  };
}
