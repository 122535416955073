export default {
  'app.common.templatePlaceholder': '请输入{label}',
  'app.common.templateSelectPlaceholder': '请选择{label}',
  'app.common.templateCharacterLength': '{label}须为{len}个字符',
  'app.common.templateCharacterMin': '{label}最少{min}个字符',
  'app.common.templateCharacterMax': '{label}最多{max}个字符',
  'app.common.templateCharacterRange': '{label}须在{min}-{max}字符之间',
  'app.common.templateHasSave': '{column}已保存',
  'app.common.templateTotal': '共 {total} 条',
  'app.common.templateType': '{label}须为{type}',
  'app.common.templatePictures': '{total}张图片',
  'app.common.templateVideos': '{total}张视频',

  'app.common.shinwell': '星威养车',
  'app.common.colon': '：',

  'app.common.simplifiedChinese': '简体中文',
  'app.common.english': '英文',
  'app.common.thai': '泰文',

  'app.common.number': '数字',

  'app.common.notification': '提示',
  'app.common.save': '保存',
  'app.common.confirm': '确定',
  'app.common.confirmWithSpace': '确 定',
  'app.common.cancel': '取消',
  'app.common.success': '成功',
  'app.common.resetWithSpace': '重 置',
  'app.common.iSee': '我知道了',
  'app.common.tips': '提示',

  'app.common.username': '用户名',
  'app.common.password': '密码',
  'app.common.newPassword': '新密码',
  'app.common.oldPassword': '旧密码',
  'app.common.forgotPassword': '找回密码',
  'app.common.mobileNumber': '手机号',
  'app.common.callingCode': '区号',
  'app.common.sms': '验证码',
  'app.common.sendSms': '获取验证码',
  'app.common.status': '状态',
  'app.common.ok': '确认',
  'app.common.fullName': '姓名',
  'app.common.email': '邮箱',
  'app.common.add': '新增',
  'app.common.import': '导入',
  'app.common.account': '账号',
  'app.common.set': '设置',
  'app.common.noPermission': '暂无权限',
  'app.common.user': '用户',
  'app.common.operate': '操作',
  'app.common.remove': '移除',
  'app.common.edit': '编辑',
  'app.common.delete': '删除',
  'app.common.okText': '确定',
  'app.common.enable': '启用',
  'app.common.disable': '禁用',
  'app.common.confirmToDelete': '确定删除吗？',
  'app.common.snapshot': '日志',
  'app.common.basic': '基本',
  'app.common.available': '可用',
  'app.common.basicInformation': '基本信息',
  'app.common.contactPerson': '联系人',
  'app.common.china': '中国',
  'app.common.malaysia': '马来西亚',
  'app.common.philippines': '菲律宾',
  'app.common.thailand': '泰国',
  'app.common.export': '导出',
  'app.common.exporting': '导出中',
  'app.common.contactMobile': '联系电话',
  'app.common.contractedStation': '签约站',
  'app.common.cooperativeStation': '合作站',
  'app.common.temporaryStation': '临时站',
  'app.common.multipleChoice': '多选',
  'app.common.brand': '品牌',
  'app.common.fitting': '配件',
  'app.components.cityArea': '省市区',
  'app.components.vehiclePlateNo': '号牌号码',
  'app.components.vehicleTeamName': '车队小组名',
  'app.components.allVehicle': '全部车辆',

  'app.common.browsersNotSupportTag': '您的浏览器不支持{tag}标签',

  'app.common.time': '时间',
  'app.common.operator': '操作人',
  'app.common.operation': '操作',
  'app.common.remark': '备注',
  'app.common.operateType': '操作类型',
  'app.common.startTime': '开始时间',
  'app.common.endTime': '结束时间',

  'app.common.menu': '菜单',
  'app.common.maint': '维保',
  'app.common.dispatch': '派单',
  'app.common.additionalDispatch': '补派单',
  'app.common.maintOrderTransfer': '转派',
  'app.common.maintOrderStationChange': '更换站点',
  'app.common.maintUncoveredCostInformSubmit': '发起保外费用确认',
  'app.common.maintOrderRecordDelegate': '代录单',
  'app.common.maintOrderQuoteAudit': '核准报价',
  'app.common.maintOrderRevoke': '作废',
  'app.common.maintOrderViewApprovedUser': '查看核准报价人信息',
  'app.common.plateNo': '车牌号',
  'app.common.maintOrderReplaceAuditor': '更换核准人',
  'app.common.maintRequestImport': '批量维保',
  'app.common.maintOrderRecheck': '复核',
  'app.common.vehicleMileage': '车辆里程',
  'app.common.vehicleMileageExport': '里程导出',
  'app.common.vehicleMileageMonthlyConfirm': '月度里程确认',
  'app.common.vehicleMileageCalibration': '人工校准',
  'app.common.vehicleMileageStatement': '里程对账',
  'app.common.vehicleMileageMonthlyReconcile': '月度对账',
  'app.common.maintPlan': '维保计划',
  'app.common.distributionCenterFittingClaimOrder': '配件领用',
  'app.common.billing': '费用账单',
  'app.common.finance': '财务',
  'app.common.stationAmercementDetail': '扣款明细',
  'app.common.stationMonthlyStatement': '对账单',
  'app.common.stationMonthlyStatementPlatformConfirm': '对账单确认',
  'app.common.stationMonthlyStatementRegenerate': '对账单作废重新生成',
  'app.common.stationMonthlyStatementSettle': '对账单结算',
  'app.common.stationMonthlyStatementPlatformExport': '对账单导出',
  'app.common.stationMonthlyStatementFeeImport': '对账单费用导入',
  'app.common.stationMonthlyStatementCommitInvoice': '对账单凭证提交',
  'app.common.stationMonthlyStatementConfirmDownload': '对账单确认函下载',
  'app.common.maintSettlementOrder': '结算账单',
  'app.common.externalBilling': '外部账单',
  'app.common.maintUncoveredBilling': '保外账单',
  'app.common.maintUncoveredBillingImportReconciliation': '导入对账状态',
  'app.common.maintUncoveredBillingImportSettlement': '导入结算状态',
  'app.common.maintUncoveredBillingExportDetail': '明细导出',
  'app.common.importMaintSettlementInfo': '导入结算状态',
  'app.common.exportMaintSettlementOrder': '明细导出',
  'app.common.importPlatformExternalBilling': '导入外部账单',
  'app.common.exportPlatformExternalBillingOrder': '导出明细',
  'app.common.exportStationExternalBillingOrder': '导出明细',
  'app.common.property': '资产',
  'app.common.vehicleManage': '车辆管理',
  'app.common.vehicleImport': '导入行驶证信息',
  'app.common.vehicleServiceInfoImport': '导入服务信息',
  'app.common.vehicleDriverImport': '导入司机信息',
  'app.common.vehicleDriverMaintain': '维护车辆司机',
  'app.common.vehicleLicenseEdit': '维护车辆行驶证',
  'app.common.vehicleDetailImport': '导入详细信息',
  'app.common.details': '详细信息',
  'app.common.vehicleEffectiveAndIneffective': '标记无效及恢复车辆',
  'app.common.vehicleTeamGroupImport': '更新小组',
  'app.common.vehicleLicImgImport': '上传/更新行驶证（车队业务）',
  'app.common.vehicleLicenseAdd': '新增车辆',
  'app.common.fleetOwnershipEdit': '编辑车队归属',
  'app.common.vehicleMaintCoverHistoryEdit': '批量更新承保记录',
  'app.common.vehicleTeamManage': '车队管理',
  'app.common.vehicleTeamOperate': '车队操作权限',
  'app.common.vehicleTeamGroupAssociated': '关联车辆',
  'app.common.vehicleTeamMaintUncoveredPipOperate': '报价加价比例',
  'app.common.vehicleTeamGroupEdit': '车队小组',
  'app.common.vehicleGroupStationMaintUncoveredPipImport': '保外加价比例导入',
  'app.common.vehicleGroupStationMaintUncoveredPipExport': '保外加价比例导出',
  'app.common.vehicleTeamMaintUncoveredOfferPriceImport': '保外协议价导入',
  'app.common.vehicleTeamMaintUncoveredOfferPriceExport': '保外协议价导出',
  'app.common.serviceStationManage': '服务站管理',
  'app.common.serviceStationViewAll': '查看全部服务站权限',
  'app.common.stationDsfittingInventoryOperate': '直供件库存管理',
  'app.common.stationAmercementView': '查看扣款',
  'app.common.serviceStationOperate': '服务站其他操作权限',
  'app.common.stationConfirmReceivedMarginPayment': '保证金收取确认',
  'app.common.stationSignContractConfirm': '签约确认',
  'app.common.stationSuspendResumeDispatch': '暂停和恢复派单',
  'app.common.stationWorkinghourPriceOperate': '工时价格操作权限',
  'app.common.stationFittingPriceOperate': '配件价格操作权限',
  'app.common.stationAmercementBatchCreate': '导入服务站扣款（财务专用）',
  'app.common.distributionCenterManage': '分拨点管理',
  'app.common.distributionCenterOperate': '分拨点操作',
  'app.common.data': '数据',
  'app.common.workinghourManage': '工时库',
  'app.common.workinghourManageEdit': '工时库操作权限',
  'app.common.fittingManage': '配件库',
  'app.common.fittingManageEdit': '配件库操作权限',
  'app.common.fittingModelManage': '型号库',
  'app.common.fittingModelManageEdit': '型号库操作权限',
  'app.common.faultManage': '故障库',
  'app.common.faultManageEdit': '故障库操作权限',
  'app.common.system': '系统',
  'app.common.userManage': '账号管理',
  'app.common.maintOrderAuditConfig': '维保单审批设置',
  'app.common.userSpecialDutyRosterImport': '排班表导入',
  'app.common.userSpecialDutyRosterExport': '排班表导出',
  'app.common.permissionManage': '角色管理',
  'app.common.phraseManage': '标签管理',
  'app.common.systemSetting': '系统设置',
  'app.common.riskControlLog': '风控日志',
  'app.common.tool': '工具',
  'app.common.systemLog': '系统日志',
  'app.common.businessDataImport': '数据导入',
  'app.common.maintOrderRecheckDispatchedImport': '待复核单据导入',
  'app.common.maintOrderRecheckDispatchedExport': '待复核单据导出',
  'app.common.anNengFittingModelMappingImport': '安能星威工时配件映射导入',
  'app.common.anNengFittingModelMappingExport': '安能星威工时配件映射导出',
  'app.common.businessDataExport': '数据导出',
  'app.common.utilityTools': '实用工具',
  'app.common.dataProcess': '数据处理',
  'app.common.productionDataProcess': '生产数据处理',
  'app.common.revokeAfterQuoteAccepted': '作废已核准维保单',
  'app.common.revokeAfterQuoteAcceptedSnapshot': '作废已核准维保单日志',
  'app.common.stationBalanceDeduction': '服务站钱包余额扣减操作',
  'app.common.stationBalanceDeductionSnapshot': '服务站钱包余额扣减日志',
  'app.common.shenTongSettledVoucherSetting': '申通结算凭证设置',
  'app.common.stationBatchMaintButter': '批量维保打黄油',
  'app.common.maintOrderViewAll': '查看全部维保单',
  'app.common.maintOrderReceive': '接单',
  'app.common.maintOrderAssign': '分配员工',
  'app.common.maintOrderCheckin': '接车',
  'app.common.maintOrderRecord': '录单',
  'app.common.maintOrderQuote': '报价',
  'app.common.maintOrderRemarkCreate': '维保单备注',
  'app.common.directSupplyFittingView': '直供件管理',
  'app.common.maintUncoveredCostInformAudit': '审核保外费用',
  'app.common.vehicleGroupManage': '车组管理',
  'app.common.vehicleTeam': '车队',
  'app.common.more': '更多',
  'app.common.noData': '暂无数据',
  'app.common.selectFromTheLeftFirst': '先从左侧选择',
  'app.common.search': '搜索',
  'app.common.filter': '筛选',
  'app.common.sort': '排序',
  'app.common.snapshotId': '快照',
  'app.common.close': '收起',
  'app.common.expand': '展开',
  'app.common.repair': '修',
  'app.common.inspect': '检',
  'app.common.keep': '养',
  'app.common.additionalDiapatch': '补',
  'app.common.idle': '闲',
  'app.common.latestExportTime': '最新导出时间',
  'app.common.download': '下载',
  'app.common.uploadPhotos': '上传照片',
  'app.common.uploadFailedTryAgain': '上传失败，请重试',
  'app.common.item': '项',
  'app.common.loading': '加载中',
  'app.common.DataExportingDescription':
    '数据正在导出，请稍后几分钟再回到本页面点击导出右侧下载按钮，下载文件。',
  'app.common.maintOrderNo': '维保单号',
  'app.common.stationAmercement': '扣款',
  'app.common.report': '报告',
  'app.common.vehicleInspectionReport': '车检报告明细',
  'app.common.vehicleTeamMonthlyInspectionReport': '月度检车报告',

  'app.common.menuSalesOrders': '销售订单',
  'app.common.menuPurchaseOrders': '采购订单',
  'app.common.menuVendor': '供应商管理',
  'app.common.menuProduct': '商品管理',
  'app.common.menuInventory': '服务站库存',
  'app.common.menuCommission': '抽佣设置',

  'app.common.appUploadAttachmentTypeRestriction':
    '上传文件的类型只能是以下类型{types}',
  'app.common.appUploadAttachmentSizeRestriction':
    '上传文件的大小不能超过{size}M',
  'app.common.appUploadAttachmentFileRestriction': '上传的附件不能为同一个文件',
  'app.common.appUploadAttachmentFailedMsg': '附件上传失败{msg}',
  'app.common.appUploadAction': '上传',
  'app.common.appUploadAttachmentFailedAgainMsg': '附件上传失败，请重试',
  'app.common.logout': '退出',

  'app.common.appUploadImageTypeRestriction':
    '上传图片的类型只能是以下类型{types}',
  'app.common.appUploadImageSizeRestriction': '上传图片的大小不能超过{size}M',
  'app.common.appUploadImageFileRestriction': '上传的图片不能为同一张图片',
  'app.common.appUploadImageFailedMsg': '图片上传失败：{msg}',
  'app.common.appUploadImageFailedAgainMsg': '图片上传失败，请重试',
  'app.common.appUploadImageQuantityRestriction':
    '上传图片的数量不能超过{limitTotal}张',
  'app.common.fittingShoppingMall': '配件商城',
  'app.common.stationFittingShoppingMall': '服务站配件商城',
  'app.common.stationFittingShoppingMallBlankTip': '请到移动客户端查看内容',
};
