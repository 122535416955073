export default {
  'billing.common.amerceMaintOrderNos': 'เลขที่กรมธรรม์การหักลดหย่อน',
  'billing.common.billingAmount': 'ค่าใช้จ่ายทั้งหมด',
  'billing.common.billingCreditedTime': 'เวลาเข้า',
  'billing.common.cooperationTypeLabel': 'ประเภทความร่วมมือ',
  'billing.common.deductedAmount': 'จำนวนเงินที่หัก',
  'billing.common.expensesStatementDetails': 'รายละเอียดบิลค่าใช้จ่าย',
  'billing.common.freedFeeTotal': 'การหักเงินทั้งหมด',
  'billing.common.maintChargeTypeLabel': 'ประเภทค่าธรรมเนียม',
  'billing.common.maintCoveredAmount': 'การรับประกันทั้งหมด',
  'billing.common.maintFeeInvoicingName': 'สถานะการออกใบแจ้งหนี้ค่าบำรุงรักษา',
  'billing.common.maintFreedAmount': 'การหักเงินทั้งหมด',
  'billing.common.maintRequestTime': 'เวลาสร้างนโยบายการบำรุงรักษา',
  'billing.common.maintUncoveredAmount': 'เงินช่วยเหลือทั้งหมด',
  'billing.common.otherFeeTotal': 'ค่าใช้จ่ายอื่นๆ',
  'billing.common.paymentMethod': 'วิธีการชำระเงิน',
  'billing.common.settledAmount': 'จำนวนเงินที่ป้อน',
  'billing.common.settlementStatusLabel': 'สถานะการตั้งถิ่นฐาน',
  'billing.common.statementNumber': 'หมายเลขคำสั่งการชำระเงิน',
  'billing.common.stationCountryCode': 'สกุลเงินการชำระบัญชี',
  'billing.common.stationId': 'รหัสสถานีบริการ',
  'billing.common.totalCostOfWorkingHoursAndAccessories':
    'ต้นทุนรวมของชั่วโมงทำงานและอุปกรณ์เสริม',
  'billing.common.trailerFeeInvoicingName':
    'สถานะการออกใบแจ้งหนี้ค่าธรรมเนียมลากจูง',
  'billing.common.vehicleTeamName': 'ชื่อทีม',
};
