export default {
  'permission.manage.add.addRole': 'เพิ่มบทบาทใหม่',
  'permission.manage.add.createUserGroup': 'สร้างกลุ่มผู้ใช้ใหม่แล้ว',
  'permission.manage.add.editRole': 'แก้ไขบทบาท',
  'permission.manage.add.permissionKeysMinLength':
    'ตรวจสอบการตั้งค่าการอนุญาตอย่างน้อยหนึ่งรายการ',
  'permission.manage.add.permissionSettings': 'การตั้งค่าการอนุญาต',
  'permission.manage.add.pleaseEnterUserGroupName':
    'กรุณากรอกชื่อกลุ่มผู้ใช้งาน',
  'permission.manage.add.roleName': 'ชื่อตัวละคร',
  'permission.manage.add.updateUserGroup':
    'ข้อมูลกลุ่มผู้ใช้ได้รับการอัปเดตแล้ว',
  'permission.manage.add.userGroupName': 'ชื่อกลุ่มผู้ใช้',
  'permission.manage.add.userGroupNamemaxLength':
    'ชื่อกลุ่มผู้ใช้ต้องมีความยาวไม่เกิน 64 อักขระ',
  'permission.manage.add.userGroupRemark': 'แสดงให้เห็น',
};
