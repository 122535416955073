export default {
  'stationAmercement.components.amerceAmount': 'จำนวนเงินที่หัก',
  'stationAmercement.components.amerceCreatedTime': 'เวลาหัก ณ ที่จ่าย',
  'stationAmercement.components.amercementReason': 'เหตุผลในการหักเงิน',
  'stationAmercement.components.amerceStatus': 'สถานะ',
  'stationAmercement.components.deductedAmount': 'การหักเงิน',
  'stationAmercement.components.image': 'รูปภาพ',
  'stationAmercement.components.maintOrderNo': 'เลขที่กรมธรรม์การบำรุงรักษา',
  'stationAmercement.components.preview': 'ดูตัวอย่าง',
  'stationAmercement.components.remainingAmerceAmount': 'หักส่วนที่เหลือ',
};
