export default {
  'vehicle.management': 'การจัดการยานพาหนะ',
  'vehicle.management.vehiclePlateNo': 'หมายเลขป้ายทะเบียน',
  'vehicle.management.fleetGroup': 'ทีมทีม',
  'vehicle.management.coverageType': 'ประเภทความคุ้มครอง',
  'vehicle.management.coverageType.select': 'กรุณาเลือกประเภทความคุ้มครอง',
  'vehicle.management.vehicleBusinessTypes': 'ประเภทธุรกิจ',
  'vehicle.management.vehicleBusinessTypes.select': 'กรุณาเลือกประเภทธุรกิจ',
  'vehicle.management.serviceManager': 'ผู้จัดการฝ่ายบริการ',
  'vehicle.management.vehicleType': 'ประเภทยานพาหนะ',
  'vehicle.management.vehicleType.select': 'กรุณาเลือกประเภทยานพาหนะ',
  'vehicle.management.covered': 'รับประกัน',
  'vehicle.management.uncovered': 'ออกประกันตัวแล้ว',
  'vehicle.management.Tractor': 'รถหลัก',
  'vehicle.management.trailer': 'รถพ่วง',
  'vehicle.management.keyword.search': 'ป้อนการเลือกคำค้นหาแบบคลุมเครือ',
  'vehicle.management.basic': 'ขั้นพื้นฐาน',
  'vehicle.management.lifecycle': 'วงจรชีวิต',
  'vehicle.management.maintenance.plan': 'แผนการบำรุงรักษา',
  'vehicle.management.maintenance.todo': 'สิ่งที่ต้องทำ',
  'vehicle.management.maintenance.inspectReport': 'รายงาน',
  'vehicle.management.maintenance.vehicleMileage': 'ระยะทาง',
  'vehicle.management.maintenance.uncoveredFault': 'ความล้มเหลวนอกการรับประกัน',
  'vehicle.management.maintenance.snapshot': 'บันทึก',
  'vehicle.management.maintenance.history': 'ประวัติการบำรุงรักษา',
  'vehicle.management.maintenance.availableFittings':
    'อุปกรณ์เสริมที่มีจำหน่าย',
  'vehicle.management.ownership.information': 'ข้อมูลการระบุแหล่งที่มา',
  'vehicle.management.fleet': 'กองทัพเรือ',
  'vehicle.management.group': 'กลุ่ม',
  'vehicle.management.residentCity': 'เมืองที่อาศัยอยู่',
  'vehicle.management.service.information': 'ข้อมูลการบริการ',
  'vehicle.management.driver.information': 'ข้อมูลไดรเวอร์',
  'vehicle.management.driver': 'คนขับ',
  'vehicle.management.binding.time': 'เวลาผูกรถ',
  'vehicle.management.inviter': 'ผู้เชิญ',
  'vehicle.management.operation': 'ดำเนินงาน',
  'vehicle.management.send.password': 'ส่งรหัสผ่านบัญชี',
  'vehicle.management.unbind': 'เลิกรวมกลุ่ม',
  'vehicle.management.unbind.confirm': 'คุณแน่ใจหรือว่าจะเลิกผูกมัด?',
  'vehicle.management.resend.password': 'ส่งรหัสผ่านบัญชีอีกครั้ง',
  'vehicle.management.logged.in': 'เข้าสู่ระบบ',
  'vehicle.management.registration.info': 'ข้อมูลใบขับขี่',
  'vehicle.management.owner': 'ทุกคน',
  'vehicle.management.usage': 'ลักษณะการใช้งาน',
  'vehicle.management.brand.model': 'รุ่นของแบรนด์',
  'vehicle.management.vin': 'หมายเลขประจำตัวยานพาหนะ (VIN)',
  'vehicle.management.engine.number': 'หมายเลขเครื่องยนต์',
  'vehicle.management.registration.date': 'วันที่ลงทะเบียน',
  'vehicle.management.issue.date': 'วันที่ออก',
  'vehicle.management.address': 'ที่อยู่',
  'vehicle.management.record.number': 'หมายเลขไฟล์',
  'vehicle.management.authorized.personnel': 'จำนวนคนที่ได้รับอนุมัติ',
  'vehicle.management.gross.weight': 'มวลรวม',
  'vehicle.management.curb.weight': 'ลดน้ำหนัก',
  'vehicle.management.dimensions': 'ขนาดโดยรวม',
  'vehicle.management.gross.combination.weight': 'มวลรวมแบบกึ่งฉุด',
  'vehicle.management.inspection.records': 'บันทึกการตรวจสอบ',
  'vehicle.management.details': 'รายละเอียด',
  'vehicle.management.vehicle.model': 'รุ่นรถ',
  'vehicle.management.emission.standard': 'มาตรฐานการปล่อยมลพิษ',
  'vehicle.management.engine.brand': 'ยี่ห้อเครื่องยนต์',
  'vehicle.management.engine.model': 'รุ่นเครื่องยนต์',
  'vehicle.management.engine.namePlate': 'ดูป้ายชื่อ',
  'vehicle.management.fuel.type': 'ประเภทเชื้อเพลิง',
  'vehicle.management.axle.configuration': 'แบบฟอร์มไดรฟ์',
  'vehicle.management.transmission.brand': 'ยี่ห้อเกียร์',
  'vehicle.management.transmission.model': 'รุ่นกระปุกเกียร์',
  'vehicle.management.def.pump.brand': 'ยี่ห้อปั๊มยูเรีย',
  'vehicle.management.def.pump.model': 'รุ่นปั๊มยูเรีย',
  'vehicle.management.front.axle.brand': 'ยี่ห้อแม่แอ๊กซ์',
  'vehicle.management.front.axle.model': 'รุ่นเพลาหน้า',
  'vehicle.management.middle.axle.brand': 'ยี่ห้อเพลากลางและหลัง',
  'vehicle.management.middle.axle.model': 'รุ่นเพลากลางและเพลาหลัง',
  'vehicle.management.battery.specs': 'ข้อมูลจำเพาะของแบตเตอรี่',
  'vehicle.management.repair': 'ซ่อมแซม',
  'vehicle.management.maintain': 'การบำรุงรักษา',
  'vehicle.management.inspect': 'พิจารณา',
  'vehicle.management.100k.km': '10,000 กิโลเมตร',
  'vehicle.management.full.screen': 'เต็มจอ',
  'vehicle.management.n.times': 'ยังไม่มีข้อความ ครั้ง',
  'vehicle.management.future.plans': 'แผนการในอนาคต',
  'vehicle.management.pending': 'ที่จะรักษา',
  'vehicle.management.maintainning': 'อยู่ระหว่างการบำรุงรักษา',
  'vehicle.management.completed': 'สมบูรณ์',
  'vehicle.management.historical.items': 'รายการทริกเกอร์ทางประวัติศาสตร์',
  'vehicle.management.one.year.plan': 'แผนการบำรุงรักษาหลังจาก 1 ปี',
  'vehicle.management.report.time': 'เวลาซ่อม',
  'vehicle.management.maintenance.items': 'โครงการบำรุงรักษา',
  'vehicle.management.odometer.km': 'ระยะทางของเครื่องมือ (กม.)',
  'vehicle.management.orderNo': 'เลขที่กรมธรรม์การบำรุงรักษา',
  'vehicle.management.maintItem.repair': 'ซ่อมแซม:',
  'vehicle.management.maintItem.accessory': 'เครื่องประดับ:',
};
