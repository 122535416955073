export default {
  'maint.components.accessoryName': 'ชื่ออุปกรณ์เสริม',
  'maint.components.addTime': 'เพิ่มเวลา',
  'maint.components.alternateContactsLabel': 'ติดต่อสำรอง',
  'maint.components.appointmentTimeLabel': 'เวลานัดหมาย',
  'maint.components.checkin.before': 'ขึ้นรถข้างหน้า',
  'maint.components.checkinOvertime':
    'หมดเวลารับสินค้า (เดิมกำหนดก่อน {dateTime})',
  'maint.components.checkInVehicleMileageImgKey': 'รับตารางไมล์',
  'maint.components.checkInVehicleMileageItemLabel': 'ไมล์สะสม',
  'maint.components.clickToView': 'คลิกเพื่อดู',
  'maint.components.completionTime': 'เวลาเสร็จสิ้น',
  'maint.components.dispatchNotesLabel': 'บันทึกการจัดส่ง',
  'maint.components.engineNumberLabel': 'หมายเลขเครื่องยนต์',
  'maint.components.estimatedPick-upTime': 'เวลารับโดยประมาณ',
  'maint.components.fare': 'ค่าโดยสาร ({currencySymbol})',
  'maint.components.faultClassification': 'การจำแนกประเภท',
  'maint.components.faultInformation': 'ข้อมูลข้อผิดพลาด',
  'maint.components.faultName': 'ชื่อความผิด',
  'maint.components.faultPhenomenonLabel': 'ปรากฏการณ์ความผิด',
  'maint.components.faultReporterLabel': 'ช่างซ่อม',
  'maint.components.fittingMaintCostCount': 'จำนวนอุปกรณ์เสริม',
  'maint.components.fleetGroupLabel': 'ทีมทีม',
  'maint.components.freightForAccessories':
    'ค่าจัดส่งอุปกรณ์เสริม ({currencySymbol})',
  'maint.components.historicalMaintenance': 'การอนุรักษ์ประวัติศาสตร์',
  'maint.components.jobContent': 'เนื้อหาการบ้าน',
  'maint.components.job-picture': 'ภาพการบ้าน',
  'maint.components.maintenanceInformation': 'ข้อมูลการบำรุงรักษา',
  'maint.components.maintenanceLocationLabel': 'สถานที่ซ่อม',
  'maint.components.maintenanceTimeLabel': 'เวลาบำรุงรักษา',
  'maint.components.maintenanceWorkerLabel': 'พนักงานซ่อมบำรุง',
  'maint.components.maintOrderNo': 'เลขที่กรมธรรม์การบำรุงรักษา',
  'maint.components.MARequirementsLabel': 'ข้อกำหนดการบำรุงรักษาชิ้นส่วน',
  'maint.components.meterMileage': 'ไมล์สะสมของเครื่องมือ',
  'maint.components.mileageAccrual': 'ไมล์สะสม',
  'maint.components.new.station.reminder': 'สถานีใหม่',
  'maint.components.new.vehicle.reminder': 'รถใหม่',
  'maint.components.new.vehicle.service.requirement': 'ดูข้อกำหนดการบริการ',
  'maint.components.nighttimeSubsidyFee':
    'ค่าธรรมเนียมเงินช่วยเหลือช่วงกลางคืน ({currencySymbol})',
  'maint.components.number': 'ปริมาณ',
  'maint.components.originalReceivingDiagram': 'แผนที่การรับของเดิม',
  'maint.components.otherFees': 'ค่าใช้จ่ายอื่นๆ',
  'maint.components.pick-upInformationLabel': 'หยิบข้อมูล',
  'maint.components.picturePreview': 'ตัวอย่างรูปภาพ',
  'maint.components.pitAdministratorLabel': 'ผู้จัดการสถานีซ่อมบำรุง',
  'maint.components.receivingDiagram': 'หยิบรูปมา',
  'maint.components.repairInformationLabel': 'ข้อมูลการซ่อม',
  'maint.components.repairLocationLabel': 'สถานที่ซ่อม',
  'maint.components.repairTimeLabel': 'เวลาซ่อม',
  'maint.components.sample': 'นี่คือตัวอย่างการตั้งชื่อ',
  'maint.components.search.placeholder.keywordNo':
    'ค้นหาตามหมายเลขกรมธรรม์การบำรุงรักษาหรือหมายเลขทะเบียนรถ',
  'maint.components.search.placeholder.maintOrderNo':
    'เลข 5 หลักสุดท้าย หรือ เลขที่ใบสั่งซื้อครบถ้วน',
  'maint.components.search.placeholder.vehiclePlateNo':
    'เลขท้าย 3 ตัวหรือทะเบียนเต็ม',
  'maint.components.serviceManagerLabel': 'ผู้จัดการฝ่ายบริการ',
  'maint.components.standardDispatcherLabel': 'กำหนดการมาตรฐาน',
  'maint.components.todoItems': 'รายการสิ่งที่ต้องทำ',
  'maint.components.towingFees': 'ค่าธรรมเนียมลากจูง({currencySymbol})',
  'maint.components.typeOfWarranty': 'ประเภทความคุ้มครอง',
  'maint.components.underWarranty': 'รับประกัน',
  'maint.components.vehicleBrandlAbel': 'รุ่นของแบรนด์',
  'maint.components.vehicleInformation': 'ข้อมูลยานพาหนะ',
  'maint.components.vehicleMileageImg': 'ภาพเลขไมล์',
  'maint.components.vehicleMileageItemLabel': 'ซ่อมไมล์',
  'maint.components.vehicleVinLabel': 'รหัสวิน',
  'maint.components.venueUsageFee':
    'ค่าธรรมเนียมการใช้สถานที่ ({currencySymbol})',
  'maint.components.workingHourAllowance': 'เบี้ยเลี้ยงชั่วโมงทำงาน',
  'maint.components.workinghourCategoryName': 'การจำแนกประเภท',
  'maint.components.workinghourName': 'ชื่อเวลาทำงาน',
  'maint.components.workinghourNumber': 'จำนวนชั่วโมงทำงาน',
};
