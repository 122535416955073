export default {
  'vehicle.team.add.vehicleTeamName': 'ชื่อทีม',
  'vehicle.team.add.vehicleTeamReportName': 'ชื่อการรายงานกองเรือ',
  'vehicle.team.add.vehicleTeamLogo': 'โลโก้ทีม',
  'vehicle.team.add.vehicleTeamAddress': 'ที่อยู่กองเรือ',
  'vehicle.team.add.detailedAddress': 'ที่อยู่โดยละเอียด',
  'vehicle.team.add.vehicleTeamDetailedAddress':
    'กรุณากรอกที่อยู่โดยละเอียดของกองเรือ',
  'vehicle.team.add.selectCountry': 'โปรดเลือกประเทศของคุณ',
  'vehicle.team.add.vehicleTeamCreate': 'สร้างข้อมูลกลุ่มยานพาหนะสำเร็จแล้ว',
  'vehicle.team.add.vehicleTeamEdit': 'อัปเดตข้อมูลกลุ่มยานพาหนะเรียบร้อยแล้ว',
  'vehicle.team.add.editVehicleTeam': 'แก้ไขกองเรือ',
};
