import antdThTH from 'ant-design-vue/es/locale/th_TH';
import dayjsTh from 'dayjs/esm/locale/th';

import appThTH from '@/locales/th-TH/app/app.js';
import homeThTH from '@/locales/th-TH/home/home.js';
import loginThTH from '@/locales/th-TH/login/login.js';
import systemLogThTH from '@/locales/th-TH/system-log/system-log.js';
import resetPasswordThTH from '@/locales/th-TH/reset-password/reset-password.js';
import maintThTH from '@/locales/th-TH/maint/maint.js';
import accountManageThTH from '@/locales/th-TH/account-manage/account-manage.js';
import userThTH from '@/locales/th-TH/user/user.js';
import permissionManageThTH from '@/locales/th-TH/permission-manage/permission-manage.js';
import vehicleTeamThTH from '@/locales/th-TH/vehicle-team/vehicle-team.js';
import vehicleManagementThTH from '@/locales/th-TH/vehicle-management/vehicle-management.js';
import stationMonthlyStatementThTH from '@/locales/th-TH/station-monthly-statement/station-monthly-statement.js';
import stationAmercementThTH from '@/locales/th-TH/station-amercement/station-amercement.js';
import stationMonthlyStatementConfirmationThTH from '@/locales/th-TH/station-monthly-statement-confirmation/station-monthly-statement-confirmation.js';
import directSupplyManageThTH from '@/locales/th-TH/direct-supply-manage/direct-supply-manage.js';
import billingThTH from '@/locales/th-TH/billing/billing.js';
import vehicleTeamMonthlyInspectionReportThTH from '@/locales/th-TH/vehicle-team-monthly-inspection-report/vehicle-team-monthly-inspection-report.js';
import vehicleInspectionReportThTH from '@/locales/th-TH/vehicle-inspection-report/vehicle-inspection-report.js';

const components = {
  localeAntd: antdThTH,
};

const plugin = {
  localeDayjs: dayjsTh,
};

const pages = {
  ...appThTH,
  ...homeThTH,
  ...loginThTH,
  ...systemLogThTH,
  ...resetPasswordThTH,
  ...maintThTH,
  ...accountManageThTH,
  ...userThTH,
  ...permissionManageThTH,
  ...vehicleTeamThTH,
  ...vehicleManagementThTH,
  ...stationMonthlyStatementThTH,
  ...stationAmercementThTH,
  ...stationMonthlyStatementConfirmationThTH,
  ...directSupplyManageThTH,
  ...billingThTH,
  ...vehicleTeamMonthlyInspectionReportThTH,
  ...vehicleInspectionReportThTH,
};

export default {
  ...components,
  ...plugin,
  ...pages,
};
