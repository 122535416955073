export default {
  'user.components.passwordTips':
    '(เว้นช่องนี้ว่างไว้หากคุณไม่ต้องการเปลี่ยนรหัสผ่าน)',
  'user.components.usernameRegExp':
    'การผสมระหว่างภาษาอังกฤษและตัวเลข โดยมีความยาวระหว่าง 7 ถึง 16 หลัก',
  'user.components.locations': 'พื้นที่ธุรกิจ',
  'user.components.basicInformation': 'ข้อมูลพื้นฐาน',
  'user.components.assigningRoles': 'มอบหมายบทบาท',
  'user.components.pleaseEnterRoleName': 'กรุณากรอกชื่อบทบาท',
  'user.components.functionName': 'ชื่อฟังก์ชัน',
  'user.components.pleaseEnterFunctionName': 'กรุณากรอกชื่อฟังก์ชั่น',
  'user.components.selectedRole': 'บทบาทที่เลือก',
  'user.components.viewPermission': 'ดูสิทธิ์',
  'user.components.distributionVehicleGroup': 'มอบหมายทีมงานกองเรือ',
  'user.components.fleetTeam': 'ทีมทีม',
  'user.components.AssignUserTeamLeader':
    'กำหนดผู้ใช้รายนี้ให้กับกลุ่มกองเรือที่เกี่ยวข้องเป็นกัปตัน (มีหลายตัวเลือก)',
};
