export default {
  'vehicle.team.user.addVehicleTeam': 'เพิ่มกลุ่มใหม่',
  'vehicle.team.user.vehiclesHaveBeenAllocated': 'มีการจัดสรรยานพาหนะแล้ว',
  'vehicle.team.user.unassignedGroup': 'กลุ่มที่ไม่ได้มอบหมาย',
  'vehicle.team.user.relateVehicle': 'ยานพาหนะที่เกี่ยวข้อง',
  'vehicle.team.user.deleteGroupContent':
    'หลังจากลบกลุ่มแล้ว ยานพาหนะจะถูกยกเลิกการกำหนด คุณต้องการยืนยันการลบหรือไม่',
  'vehicle.team.user.vehicleTeam': 'กลุ่มยานพาหนะที่เกี่ยวข้อง',
  'vehicle.team.user.vehicleGroupName': 'ชื่อกลุ่ม',
  'vehicle.team.user.leaderUsers': 'กัปตัน',
  'vehicle.team.user.pleaseSelectLeaderUsers': 'กรุณาเลือกหัวหน้าทีม',
  'vehicle.team.user.vehicleGroupNameMaxLength':
    'ชื่อกลุ่มต้องมีความยาวไม่เกิน 128 อักขระ',
  'vehicle.team.user.vehicleGroupHasSave': 'บันทึกข้อมูลกลุ่มทีมเรียบร้อยแล้ว',
  'vehicle.team.user.showDeletedGroups': 'แสดงกลุ่มที่ถูกลบ',
  'vehicle.team.user.recover': 'ฟื้นตัว',
  'vehicle.team.user.confirmRecovery': 'ยืนยันการกู้คืนใช่ไหม',
};
