export default {
  'stationMonthlyStatement.components.address': 'ที่อยู่',
  'stationMonthlyStatement.components.addressee': 'ผู้รับ',
  'stationMonthlyStatement.components.amercement': 'การหักเงิน',
  'stationMonthlyStatement.components.amercementFee':
    'จำนวนเงินที่หัก ({unit})',
  'stationMonthlyStatement.components.amercementFeeDescription':
    '(ยกยอดจากเดือนที่แล้ว: {historyAmount} ใหม่ในเดือนนี้: {currentAmount})',
  'stationMonthlyStatement.components.bankAccount': 'หมายเลขบัญชีธนาคาร',
  'stationMonthlyStatement.components.carRepairAmount': 'ปริมาณการซ่อมแซม',
  'stationMonthlyStatement.components.channelManagerUser': 'ผู้จัดการช่อง',
  'stationMonthlyStatement.components.commissionAmount':
    'จำนวนค่าคอมมิชชัน ({unit})',
  'stationMonthlyStatement.components.companyName': 'ชื่อบริษัท',
  'stationMonthlyStatement.components.confirm': 'ยืนยัน',
  'stationMonthlyStatement.components.confirmationLetter': 'หนังสือยืนยัน',
  'stationMonthlyStatement.components.confirmedVoucherFilesLabel':
    'รูปภาพ {textConfirmedVoucherFiles} ที่ประทับตรา:',
  'stationMonthlyStatement.components.contactPerson': 'ผู้ติดต่อ',
  'stationMonthlyStatement.components.depositBank': 'ธนาคารแห่งการฝาก',
  'stationMonthlyStatement.components.directSupplyFittingDescription':
    '(ยกยอดจากเดือนที่แล้ว: {historyAmount} ใหม่ในเดือนนี้: {currentAmount})',
  'stationMonthlyStatement.components.directSupplyFittingDiscountFee':
    'หักต้นทุนของชิ้นส่วนอุปทานโดยตรง ({unit})',
  'stationMonthlyStatement.components.directSupplyFittingFee':
    'ต้นทุนการจัดซื้อชิ้นส่วนทางตรง ({unit})',
  'stationMonthlyStatement.components.estimatedSettlementDate':
    'วันที่ชำระหนี้โดยประมาณ',
  'stationMonthlyStatement.components.finalOtherSettleAmount':
    '(การบำรุงรักษาอันอิง: {finalSpecialSettleAmount}, การบำรุงรักษายานพาหนะอื่นๆ: {finalOtherSettleAmount})',
  'stationMonthlyStatement.components.finalSettleAmount':
    'จำนวนเงินที่ชำระจริง ({unit})',
  'stationMonthlyStatement.components.fittingDiscountFee': 'การหักอุปกรณ์เสริม',
  'stationMonthlyStatement.components.invoice': 'ใบแจ้งหนี้',
  'stationMonthlyStatement.components.invoiceAmount':
    'จำนวนใบแจ้งหนี้ ({unit})',
  'stationMonthlyStatement.components.invoiceConfirmationMailingTitle':
    'ใบแจ้งหนี้และข้อมูลการส่งจดหมายยืนยัน',
  'stationMonthlyStatement.components.invoiceInfo': 'ข้อมูลการเรียกเก็บเงิน',
  'stationMonthlyStatement.components.invoiceMessagePartOne':
    'กรุณาออกใบแจ้งหนี้เป็นจำนวน',
  'stationMonthlyStatement.components.invoiceMessagePartTwo':
    'เกี่ยวกับอุปกรณ์เสริมที่บริษัทของคุณซื้อ หากคุณต้องการออกใบแจ้งหนี้ โปรดติดต่อ Channel manager',
  'stationMonthlyStatement.components.invoicingAbility':
    'ความสามารถในการออกใบแจ้งหนี้',
  'stationMonthlyStatement.components.mailingAddress': 'ที่อยู่ทางไปรษณีย์',
  'stationMonthlyStatement.components.maintenanceSummary': 'สรุปการบำรุงรักษา',
  'stationMonthlyStatement.components.marginAmount': 'ระยะขอบ',
  'stationMonthlyStatement.components.marginAmountDeduct':
    'จำนวนเงินฝากที่จะหัก ({unit})',
  'stationMonthlyStatement.components.marginAmountStatus': '(ระยะขอบ {label})',
  'stationMonthlyStatement.components.maxInvoiceVoucherFilesVerifyMessage':
    '{textInvoiceVoucherFiles} ต้องไม่เกิน {maxInvoiceVoucherFiles}',
  'stationMonthlyStatement.components.maxOtherVoucherFilesVerifyMessage':
    '{textOtherVoucherFiles} ต้องไม่เกิน {maxOtherVoucherFiles}',
  'stationMonthlyStatement.components.maxPhotoVerifyMessage':
    'จำนวนรูปภาพที่ประทับตรา {textConfirmedVoucherFiles} ต้องไม่เกิน {maxConfirmedVoucherFiles}',
  'stationMonthlyStatement.components.mobileNumber': 'โทรศัพท์',
  'stationMonthlyStatement.components.otherFee': 'ค่าใช้จ่ายอื่นๆ ({unit})',
  'stationMonthlyStatement.components.otherFeeRemarkLabel':
    'หมายเหตุค่าธรรมเนียมอื่น ๆ',
  'stationMonthlyStatement.components.otherSettlementVoucher':
    'หนังสือรับรองอื่น ๆ',
  'stationMonthlyStatement.components.pleaseUpload': 'กรุณาอัพโหลด',
  'stationMonthlyStatement.components.repairAmount':
    'จำนวนเงินค่าซ่อม ({unit})',
  'stationMonthlyStatement.components.repairTimes': 'จำนวนการซ่อมรถ',
  'stationMonthlyStatement.components.settledAmount':
    'จำนวนเงินที่ชำระ ({unit})',
  'stationMonthlyStatement.components.settledTime': 'วันที่ชำระบัญชี',
  'stationMonthlyStatement.components.settlementAmount':
    'จำนวนเงินที่ชำระ ({unit})',
  'stationMonthlyStatement.components.settlementRatio': 'อัตราส่วนการชำระบัญชี',
  'stationMonthlyStatement.components.settlementSituation':
    'สถานการณ์การตั้งถิ่นฐาน',
  'stationMonthlyStatement.components.settlementVoucherTitle':
    'บัตรกำนัลการชำระเงิน',
  'stationMonthlyStatement.components.statementTime': 'เวลาแจ้งยอด',
  'stationMonthlyStatement.components.stationInformation': 'ข้อมูลสถานีบริการ',
  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartFive':
    ' (4) จำนวนเงินที่ชำระจริงของ {filterToChineseDate} คือ {unit}{filterFinalSettleAmount}',
  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartFour':
    '(3) จำนวนเงินที่หักที่สร้างโดย {filterToChineseDate} คือ {unit}{filterAmercementFee}',
  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartOne':
    'สวัสดี โปรดยืนยันอย่างรอบคอบ:',
  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartSix':
    ' เมื่อได้รับการยืนยันแล้ว Xingwei จะไม่รับผิดชอบและชำระค่าซ่อมแซมนอกเหนือจากคำชี้แจงข้อตกลงนี้ก่อนถึงกำหนดเวลาอีกต่อไป',
  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartThree':
    ' (2) ตามการสื่อสาร จำนวนการซื้อชิ้นส่วนอุปทานโดยตรง {filterToChinaDate} คือ {unit}{filterDirectSupplyFittingFee} และจำนวนการซื้อชิ้นส่วนอุปทานโดยตรงที่ควรหักออกจากค่าบำรุงรักษาคือ {unit}{filterDirectSupplyFittingDiscountFee}',
  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartTwo':
    ' (1) ณ วันที่ {filterToTheLastDayOfCurrentMonth} จำนวนค่าธรรมเนียมการซ่อมทั้งหมดที่ Xingwei จะต้องชำระคือ {unit}{filterRepairFee} และไม่มีการเรียกเก็บเงินค่าซ่อมที่ค้างชำระ',
  'stationMonthlyStatement.components.stationMonthlyStatementStationBasicInfoTip':
    'Xingwei Finance จะอัปเดตหลังจากได้รับจดหมายยืนยันและใบแจ้งหนี้ที่ประทับตราแล้ว',
  'stationMonthlyStatement.components.stationName': 'ชื่อสถานีบริการ',
  'stationMonthlyStatement.components.taxNumber': 'หมายเลขประจำตัวผู้เสียภาษี',
  'stationMonthlyStatement.components.taxRate': 'อัตราภาษี',
  'stationMonthlyStatement.components.textInvoiceVoucherFiles':
    'รูปถ่ายใบแจ้งหนี้หรือ PDF',
  'stationMonthlyStatement.components.totalSettledAmount':
    'จำนวนเงินที่ชำระทั้งหมด ({unit})',
  'stationMonthlyStatement.components.unsettledAmount':
    'จำนวนเงินที่ยังไม่ชำระ ({unit})',
  'stationMonthlyStatement.components.uploadingSettlementSucceeded':
    'อัปโหลดใบสำคัญการชำระเงินเรียบร้อยแล้ว',
  'stationMonthlyStatement.components.waitUnsettledAmount':
    'จำนวนเงินที่ต้องชำระ ({unit})',
};
