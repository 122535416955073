import permissionManageCommon from '@/locales/th-TH/permission-manage/permission-manage-common.js';
import permissionManageComponents from '@/locales/th-TH/permission-manage/permission-manage-components.js';
import permissionManageAdd from '@/locales/th-TH/permission-manage/permission-manage-add.js';
import permissionManageDetails from '@/locales/th-TH/permission-manage/permission-manage-details.js';

export default {
  ...permissionManageCommon,
  ...permissionManageComponents,
  ...permissionManageAdd,
  ...permissionManageDetails,
};
