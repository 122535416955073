export default {
  'stationMonthlyStatement.common.confirmationDetailDownloadTitle':
    'กรุณาดาวน์โหลดและพิมพ์จดหมายยืนยัน ประทับตราอย่างเป็นทางการ และส่งไปรษณีย์ไปยังที่อยู่ด้านล่าง หากคุณมีคำถามใด ๆ โปรดติดต่อ Channel manager ได้ทันเวลา',
  'stationMonthlyStatement.common.downloadConfirmLetter':
    'ดาวน์โหลดจดหมายยืนยัน',
  'stationMonthlyStatement.common.export': 'ส่งออก',
  'stationMonthlyStatement.common.expressCompany': 'บริษัทจัดส่ง',
  'stationMonthlyStatement.common.expressCompanyName': 'ชื่อบริษัทด่วน',
  'stationMonthlyStatement.common.expressNumber': 'หมายเลขติดตามด่วน',
  'stationMonthlyStatement.common.pleaseUploadVoucher':
    'โปรดอัปโหลดใบรับรองก่อน',
  'stationMonthlyStatement.common.save': 'บันทึก',
  'stationMonthlyStatement.common.statement': 'คำแถลง',
  'stationMonthlyStatement.common.stationExportStatement':
    'คำชี้แจงการส่งออกของสถานีบริการ',
  'stationMonthlyStatement.common.uploadVoucher': 'อัปโหลดข้อมูลประจำตัว',
  'stationMonthlyStatement.common.viewVoucher': 'ดูบัตรกำนัล',
};
